import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompetency, setSertifikasiCompetency } from "../../redux/modules/profile.slice";
import { updateSertifikasiCompetency } from "../../redux/auth.slice";
import PdfLink from "../../helper/PDFLink";
import {
	getCertificateList,
	getEducations,
} from "../../redux/modules/public.slice";
import Select from "react-select";
import { setKompetensiCrew } from "../../services/CVStorage";
import Swal from "sweetalert2";
import { collectDataKompetensi } from "../../redux/modules/collect.slice";
import { toast } from "react-hot-toast";
import { notifyError } from "../../components/notification/Toast";

const FormSertifikasiCompetency = ({
	isSubmitting,
	data,
	isPublic,
	next,
	prev,
	setReadyOnSave,
	readyOnSave,
}) => {
	const initialState = {
		certificate_of_competency:
			data?.length > 0
				? data
				: [
						{
							certificate_list_id: null,
							// education_id: null,
							sub_education: "none",
							document_number: "",
							place_issued: "",
							date_issued: null,
							validity_period: null,
							file: null,
						},
				  ],
	};
	const dispatch = useDispatch();

	const [listCertificate, setCertificateOptions] = useState([]);
	const [loading, setLoading] = useState(false)

	const [alias, setAlias] = useState('')

	const validationCoC = Yup.object().shape({
		certificate_of_competency: Yup.array().of(
			Yup.object().shape({
				certificate_list_id: Yup.mixed().required("Form ini harus terisi."),
				document_number: Yup.mixed().required("Form ini harus terisi."),
				place_issued: Yup.mixed().required("Form ini harus terisi."),
				date_issued: Yup.mixed().required("Form ini harus terisi."),
				// validity_period: Yup.mixed().required("Form ini harus terisi."),
				file: Yup.mixed().required("Form ini harus terisi."),
			})
		),
	});

	const handleFileSelect = (event, index, setFieldValue) => {
		const file = event.target.files[0];

		var fileSizeInMB = file.size / (1024 * 1024);

		if(fileSizeInMB > 10){
			return notifyError('File tidak boleh lebih dari 10 MB')
		}
		setFieldValue(`certificate_of_competency[${index}].file`, file);
	};

	const eventAddData = (values, setFieldValue) => {
		let newArr = [...values.certificate_of_competency];
		newArr.push({
			certificate_list_id: "",
			// education_id: null,
			sub_education: "none",
			document_number: "",
			place_issued: "",
			date_issued: null,
			validity_period: null,
			file: null,
		});
		setFieldValue("certificate_of_competency", newArr);
	};

	const handleDokumenListChange = (e, index, setFieldValue) => {
		setFieldValue(
			`certificate_of_competency[${index}].certificate_list_id`,
			e.value
		);

		// setCertificateOptions((prevItems) =>
		// 	prevItems.filter((item) => item.value !== Number(e.value))
		// );

		const obj = listCertificate.find(val => val.value === Number(e.value))

		setAlias(obj.alias)
	};

	useEffect(() => {
		const fetchListCertificate = async () => {
			let params = {
				type: "competency",
			};

			const res = await dispatch(getCertificateList(params));

			if (res.payload.status === true) {
				let tempArr = [];
				res.payload.data.data.map((val) => {
					tempArr.push({
						value: val.id,
						alias: val.alias,
						label: val.name,
					});
				});

				setCertificateOptions(tempArr);
			}
		};
	
		fetchListCertificate();
	}, []);

	const onDelete = (values, index, setFieldValue) => {
		if(!values.certificate_of_competency[index].id){
		  return  setFieldValue(
			"certificate_of_competency",
			values.certificate_of_competency.filter(
				(_, i) => i !== index
			)
		)
		}
  
		Swal.fire({
		  title: 'Apakah yakin ingin menghapus data ini?',
		  text: "Data yang terhapus tidak akan kembali.",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Ya, Hapus!'
		}).then(async (result) => {
		  if (result.isConfirmed) {
  
			const res = await dispatch(deleteCompetency(values.certificate_of_competency[index].id))
  
			if(res.payload.status === true){
				setFieldValue(
					"certificate_of_competency",
					values.certificate_of_competency.filter((_, i) => i !== index)
				  )
		
				  Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				  )
			}
		  }
		})
	}

	const onSubmit = async (values) => {
		if (isPublic) {
			setReadyOnSave({
				...readyOnSave,
				certificate_of_competency: values.certificate_of_competency,
			});
			setKompetensiCrew(values);

			setLoading(true)

			let formData = new FormData()

			values?.certificate_of_competency?.forEach((item, index) => {
			  for (const property in item) {
				formData.append(`certificate_of_competency[${index}][${property}]`, item[property]);
			  }
			});
	  
			let user_id = localStorage.getItem('user_id')
	  
			const res = await dispatch(collectDataKompetensi({id: Number(user_id), data:formData}))
	  
			if(res.payload.data.status === false || res.payload.status === 400){
			  setLoading(false)
			  toast.error(res.payload.data.data);
			  return 
			}
			toast.success('Simpan data kompetensi berhasil.');
			setLoading(false)
	  
			return next();
		}

		let newArr = [];
		values.certificate_of_competency.forEach((val, index) => {
			newArr.push({
				id:val.id ?? null,
				certificate_list_id: val.certificate_list_id.value ?? val.certificate_list_id,
				// education_id: null,
				sub_education: val.sub_education,
				document_number: val.document_number,
				place_issued: val.place_issued,
				date_issued: val.date_issued,
				validity_period: val.validity_period,
				file: val.file,
			});
			if(typeof val.file === 'string'){
				delete newArr[index].file
			} else if (!newArr[index].id){
				delete newArr[index].id
			}
		});

		const formData = new FormData();

		newArr.forEach((item, index) => {
			for (const property in item) {
				formData.append(
					`certificate_of_competency[${index}][${property}]`,
					item[property]
				);
			}
		});

		const res = await dispatch(setSertifikasiCompetency(formData));

		if (res.payload.status === true) {
			dispatch(updateSertifikasiCompetency(newArr));
		}
	};

	return (
		<>
			<div className="flex mb-4">
				<div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
					<h1 className="text-xl text-white font-bold ">
						Sertifikasi Kompetensi
					</h1>
				</div>
			</div>
			<Formik
				initialValues={initialState}
				onSubmit={onSubmit}
				validationSchema={validationCoC}
			>
				{({
					errors,
					touched,
					values,
					setFieldTouched,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => (
					<Form className="text-sm">
						{values?.certificate_of_competency?.map((val, index) => (
							<div
								className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
								key={index}
							>
								<div className="mb-3 col-span-6 sm:col-span-4">
									<label>Nama Dokumen</label>
									<Select
										className={`react-select`}
										classNamePrefix="react-select"
										options={listCertificate}
										isSearchable={false}
										onChange={(e) =>
											handleDokumenListChange(e, index, setFieldValue)
										}
										onBlur={handleBlur}
										name={`certificate_of_competency[${index}].certificate_list_id`}
										id={`certificate_of_competency[${index}].certificate_list_id`}
										value={listCertificate.find(
											(option) =>
												option.value ===
												values?.certificate_of_competency?.[index]
													?.certificate_list_id
										)}
									/>

									<ErrorMessage
										name={`certificate_of_competency[${index}].certificate_list_id`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								{/* <div className="mb-3 col-span-6 sm:col-span-4">
									<label>Pendidikan</label>

									<select
										className="input-text  "
										name={`certificate_of_competency[${index}].education_id`}
										id={`certificate_of_competency[${index}].education_id`}
										value={
											values?.certificate_of_competency?.[index]?.education_id
										}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`certificate_of_competency[${index}].education_id`,
												e.target.value
											);
										}}
									>
										<option value="">Select Pendidikan...</option>
										{listEducation?.map((val, index) => (
											<option key={index} value={val.value}>
												{val.text}
											</option>
										))}
									</select>
									<ErrorMessage
										name={`certificate_of_competency[${index}].education_id`}
										component="div"
										style={{ color: "red" }}
									/>
								</div> */}
								{alias!= 'ant1' &&
									alias != 'att1' && (
										<div className="mb-3 col-span-6 sm:col-span-4">
											<label>Level</label>
											<select
												className="input-text  "
												name={`certificate_of_competency[${index}].sub_education`}
												id={`certificate_of_competency[${index}].sub_education`}
												value={
													values?.certificate_of_competency?.[index]
														?.sub_education
												}
												onBlur={handleBlur}
												onChange={(e) => {
													setFieldValue(
														`certificate_of_competency[${index}].sub_education`,
														e.target.value
													);
												}}
											>
												<option value="">Select Level...</option>
												<option value="management">Manajemen level</option>
												<option value="operational">Operational level</option>
											</select>
											<ErrorMessage
												name={`certificate_of_competency[${index}].sub_education`}
												component="div"
												style={{ color: "red" }}
											/>
										</div>
								)}
								<div className="mb-3  col-span-6 sm:col-span-4">
									<label>No Dokumen</label>
									<Field
										className="input-text  "
										name={`certificate_of_competency[${index}].document_number`}
										id={`certificate_of_competency[${index}].document_number`}
										value={
											values?.certificate_of_competency?.[index]
												?.document_number
										}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`certificate_of_competency[${index}].document_number`,
												e.target.value
											);
										}}
										placeholder="No dokumen..."
									/>
									<ErrorMessage
										name={`certificate_of_competency[${index}].document_number`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3  col-span-6 sm:col-span-4">
									<label>Tempat diterbitkan</label>
									<Field
										className="input-text  "
										name={`certificate_of_competency[${index}].place_issued`}
										id={`certificate_of_competency[${index}].place_issued`}
										value={
											values?.certificate_of_competency?.[index]?.place_issued
										}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`certificate_of_competency[${index}].place_issued`,
												e.target.value
											);
										}}
										placeholder="Place Issued..."
									/>
									<ErrorMessage
										name={`certificate_of_competency[${index}].place_issued`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3  col-span-6 sm:col-span-4">
									<label>Tanggal Terbit</label>
									<Field
										type="date"
										className="input-text  "
										name={`certificate_of_competency[${index}].date_issued`}
										id={`certificate_of_competency[${index}].date_issued`}
										value={
											values?.certificate_of_competency?.[index]?.date_issued
										}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`certificate_of_competency[${index}].date_issued`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`certificate_of_competency[${index}].date_issued`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3  col-span-6 sm:col-span-4">
									<label>Tanggal Berakhir</label>
									<Field
										type="date"
										className="input-text  "
										name={`certificate_of_competency[${index}].validity_period`}
										id={`certificate_of_competency[${index}].validity_period`}
										value={
											values?.certificate_of_competency?.[index]
												?.validity_period
										}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`certificate_of_competency[${index}].validity_period`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`certificate_of_competency[${index}].validity_period`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="col-span-6 sm:col-span-4">
									<label>File</label>

									<input
										type="file"
										className="input-text  "
										name={`certificate_of_competency[${index}].file`}
										id={`certificate_of_competency[${index}].file`}
										onBlur={handleBlur}
										onChange={(event) =>
											handleFileSelect(event, index, setFieldValue)
										}
										accept="image/*, application/pdf"
										placeholder="File..."
									/>
									<div>
										{values?.certificate_of_competency?.[index]?.file_url ? (
											<PdfLink
												url={values?.certificate_of_competency?.[index]?.file_url}
											/>
										) : (
											<span className="text-red-500">{!isPublic && 'No file'}</span>
										)}
									</div>
									<span className="text-gray-400 text-xs">File Size tidak boleh melebihi <strong className="text-[#29B6AE]">10 MB</strong></span>

									<ErrorMessage
										name={`certificate_of_competency[${index}].file`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								{index === 0 ? (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Tambah</label>
										<button
											type="button"
											className={`button-submit block mb-4 ${
												listCertificate.length < 1 && "bg-gray-500"
											}`}
											onClick={() => eventAddData(values, setFieldValue)}
											disabled={listCertificate.length < 1 ? true : false}
										>
											<FontAwesomeIcon
												icon="plus"
												size="lg"
												className="text-white "
											/>
										</button>
									</div>
								) : (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Hapus</label>
										<button
											type="button"
											className="button-submit block mb-4 bg-red-500"
											onClick={() =>
												onDelete(values, index, setFieldValue)
											}
										>
											<FontAwesomeIcon
												icon="trash"
												size="lg"
												className="text-white"
											/>
										</button>
									</div>
								)}
							</div>
						))}

						<div className="text-center lg:text-left flex mt-4">
							{isPublic && (
								<button
									type="button"
									className="button-submit block mr-2"
									onClick={() => {
										prev();
									}}
								>
									{`<`} Prev
								</button>
							)}

							<button type="submit" className="button-submit block">
								{isSubmitting || loading ? (
									<FontAwesomeIcon
										icon="spinner"
										size="xl"
										className="text-white animate-spin"
									/>
								) : isPublic ? (
									"Simpan >"
								) : (
									"Save Data"
								)}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormSertifikasiCompetency;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteTravelDokumen,
	getMasterDocumentList,
	setTravelDocument,
} from "../../redux/modules/profile.slice";
import { updateSertifikasiTravelDocument } from "../../redux/auth.slice";
import PdfLink from "../../helper/PDFLink";
import Select from "react-select";
import { setDokumenPerjalananCrew } from "../../services/CVStorage";
import Swal from "sweetalert2";
import { collectDataTravel } from "../../redux/modules/collect.slice";
import { toast } from "react-hot-toast";
import { notifyError } from "../../components/notification/Toast";

const FormTravelDocument = ({
	isSubmitting,
	data,
	isPublic,
	next,
	prev,
	setReadyOnSave,
	readyOnSave,
}) => {
	const initialState = {
		travel_document:
			data?.length > 0
				? data
				: [
						{
							document_list_id: "",
							document_number: "",
							place_issued: "",
							date_issued: null,
							validity_period: null,
							file: null,
						},
				  ],
	};
	const dispatch = useDispatch();

	const [manualDocument, setManualDocument] = useState([]);
	const [loading, setLoading] = useState(false);

	const validationTravelDocument = Yup.object().shape({
		travel_document: Yup.array().of(
			Yup.object().shape({
				document_list_id: Yup.mixed().required("Form ini harus terisi."),
				document_number: Yup.mixed().required("Form ini harus terisi."),
				place_issued: Yup.mixed().required("Form ini harus terisi."),
				date_issued: Yup.mixed().required("Form ini harus terisi."),
				// validity_period: Yup.mixed().required("Form ini harus terisi."),
				file: Yup.mixed().required("Form ini harus terisi."),
			})
		),
	});

	const handleFileSelect = (event, index, setFieldValue) => {
		const file = event.target.files[0];

		var fileSizeInMB = file.size / (1024 * 1024);

		if(fileSizeInMB > 10){
			return notifyError('File tidak boleh lebih dari 10 MB')
		}

		setFieldValue(`travel_document[${index}].file`, file);
	};

	const eventAddData = (values, setFieldValue) => {
		let newArr = [...values.travel_document];
		newArr.push({
			document_list_id: "",
			document_number: "",
			place_issued: "",
			date_issued: null,
			validity_period: null,
			file: null,
		});
		setFieldValue("travel_document", newArr);
	};

	const handleDokumenListChange = (e, index, setFieldValue) => {
		setFieldValue(`travel_document[${index}].document_list_id`, e.value);

		// setManualDocument((prevItems) =>
		// 	prevItems.filter((item) => item.value !== Number(e.value))
		// );
	};

	useEffect(() => {
		const fetchDocumentList = async () => {
			let params = {
				type: "manual",
				is_travel: 1,
			};

			let newManual = [];
			const res = await dispatch(getMasterDocumentList(params));
			if (res.payload.status) {
				res.payload.data.data.map((val) => {
					newManual.push({
						label: val.name,
						value: val.id,
					});
				});
				setManualDocument(newManual);
			}
		};
		fetchDocumentList();
	}, []);

	const onDelete = (values, index, setFieldValue) => {
		if(!values.travel_document[index].id){
		  return  setFieldValue(
			"travel_document",
			values.travel_document.filter((_, i) => i !== index)
		)
		}
  
		Swal.fire({
		  title: 'Apakah yakin ingin menghapus data ini?',
		  text: "Data yang terhapus tidak akan kembali.",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Ya, Hapus!'
		}).then(async (result) => {
		  if (result.isConfirmed) {
  
			const res = await dispatch(deleteTravelDokumen(values.travel_document[index].id))
  
			if(res.payload.status === true){
				setFieldValue(
					"travel_document",
					values.travel_document.filter((_, i) => i !== index)
				  )
		
				  Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				  )
			}
		  }
		})
	}

	const onSubmit = async (values) => {
		if (isPublic) {
			setDokumenPerjalananCrew(values);
			setReadyOnSave({
				...readyOnSave,
				travel_document: values.travel_document,
			});

			setLoading(true)

			let formData = new FormData()

			values?.travel_document?.forEach((item, index) => {
			  for (const property in item) {
				formData.append(`travel_document[${index}][${property}]`, item[property]);
			  }
			});
	  
			let user_id = localStorage.getItem('user_id')
	  
			const res = await dispatch(collectDataTravel({id: Number(user_id), data:formData}))
	  
			if(res.payload.data.status === false || res.payload.status === 400){
			  setLoading(false)
			  toast.error(res.payload.data.data);
			  return 
			}
			toast.success('Simpan data dokumen perjalanan berhasil.');
			setLoading(false)
	  
			return next();
		}

		let newArr = [];
		values.travel_document.forEach((val,index) => {
			newArr.push({
				id:val.id,
				document_list_id: val.document_list_id,
				document_number: val.document_number,
				place_issued: val.place_issued,
				date_issued: val.date_issued,
				validity_period: val.validity_period,
				file: val.file,
			});
			if(typeof val.file === 'string'){
				delete newArr[index].file
			} else if (!newArr[index].id){
				delete newArr[index].id
			}
		});

		const formData = new FormData();

		newArr.forEach((item, index) => {
			for (const property in item) {
				formData.append(
					`travel_document[${index}][${property}]`,
					item[property]
				);
			}
		});

		const res = await dispatch(setTravelDocument(formData));

		if (res.payload.status === true) {
			dispatch(updateSertifikasiTravelDocument(newArr));
		}
	};

	return (
		<>
			<div className="flex mb-4">
				<div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
					<h1 className="text-xl text-white font-bold ">Dokumen Perjalanan</h1>
				</div>
			</div>
			<Formik
				initialValues={initialState}
				onSubmit={onSubmit}
				validationSchema={validationTravelDocument}
			>
				{({
					errors,
					touched,
					values,
					setFieldTouched,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => (
					<Form className="text-sm">
						{values?.travel_document?.map((val, index) => (
							<div
								className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
								key={index}
							>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Nama Dokumen</label>
									<Select
										className={`react-select`}
										classNamePrefix="react-select"
										options={manualDocument}
										isSearchable={false}
										onChange={(e) =>
											handleDokumenListChange(e, index, setFieldValue)
										}
										onBlur={handleBlur}
										name={`travel_document[${index}].document_list_id`}
										id={`travel_document[${index}].document_list_id`}
										value={manualDocument.find(
											(option) =>
												option.value ===
												values?.travel_document?.[index]?.document_list_id
										)}
									/>

									<ErrorMessage
										name={`travel_document[${index}].document_list_id`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-4">
									<label>No Dokumen</label>
									<Field
										className="input-text  "
										name={`travel_document[${index}].document_number`}
										id={`travel_document[${index}].document_number`}
										value={values?.travel_document?.[index]?.document_number}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`travel_document[${index}].document_number`,
												e.target.value
											);
										}}
										placeholder="No dokumen..."
									/>
									<ErrorMessage
										name={`travel_document[${index}].document_number`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Tempat diterbitkan</label>
									<Field
										className="input-text  "
										name={`travel_document[${index}].place_issued`}
										id={`travel_document[${index}].place_issued`}
										value={values?.travel_document?.[index]?.place_issued}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`travel_document[${index}].place_issued`,
												e.target.value
											);
										}}
										placeholder="Place Issued..."
									/>
									<ErrorMessage
										name={`travel_document[${index}].place_issued`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-4">
									<label>Tanggal Terbit</label>
									<Field
										type="date"
										className="input-text  "
										name={`travel_document[${index}].date_issued`}
										id={`travel_document[${index}].date_issued`}
										value={values?.travel_document?.[index]?.date_issued}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`travel_document[${index}].date_issued`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`travel_document[${index}].date_issued`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Tanggal Berakhir</label>
									<Field
										type="date"
										className="input-text  "
										name={`travel_document[${index}].validity_period`}
										id={`travel_document[${index}].validity_period`}
										value={values?.travel_document?.[index]?.validity_period}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`travel_document[${index}].validity_period`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`travel_document[${index}].validity_period`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-3">
									<label>File</label>

									<input
										type="file"
										className="input-text  "
										name={`travel_document[${index}].file`}
										id={`travel_document[${index}].file`}
										accept="image/*, application/pdf"
										onBlur={handleBlur}
										onChange={(event) =>
											handleFileSelect(event, index, setFieldValue)
										}
										placeholder="File..."
									/>
									<div>
										{values?.travel_document?.[index]?.file_url ? (
											<PdfLink url={values?.travel_document?.[index]?.file_url} />
										) : (
											<span className="text-red-500">{!isPublic && 'No file'}</span>
										)}
									</div>
									<span className="text-gray-400 text-xs">File Size tidak boleh melebihi <strong className="text-[#29B6AE]">10 MB</strong></span>
									
									<ErrorMessage
										name={`travel_document[${index}].file`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								{index === 0 ? (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Tambah</label>
										<button
											type="button"
											className={`button-submit block mb-4 ${
												manualDocument.length < 1 && "bg-gray-500"
											}`}
											onClick={() => eventAddData(values, setFieldValue)}
											disabled={manualDocument.length < 1 ? true : false}
										>
											<FontAwesomeIcon
												icon="plus"
												size="lg"
												className="text-white "
											/>
										</button>
									</div>
								) : (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Hapus</label>
										<button
											type="button"
											className="button-submit block mb-4 bg-red-500"
											onClick={() =>
												onDelete(values, index, setFieldValue)
											}
										>
											<FontAwesomeIcon
												icon="trash"
												size="lg"
												className="text-white"
											/>
										</button>
									</div>
								)}
							</div>
						))}
						<div className="text-center lg:text-left flex mt-4">
							{isPublic && (
								<button
									type="button"
									className="button-submit block mr-2"
									onClick={() => {
										prev();
									}}
								>
									{`<`} Prev
								</button>
							)}

							<button type="submit" className="button-submit block">
								{isSubmitting || loading ? (
									<FontAwesomeIcon
										icon="spinner"
										size="xl"
										className="text-white animate-spin"
									/>
								) : isPublic ? (
									"Simpan >"
								) : (
									"Save Data"
								)}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormTravelDocument;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userFemale from "../../../assets/user/11.png";
import userMale from "../../../assets/user/09.jpg";
import {
  UserGroupIcon,
  DocumentDuplicateIcon,
  UsersIcon,
  BriefcaseIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import FormDataDiri from "../../../containers/edit-profile/FormDataDiri";
import FormOrganisasi from "../../../containers/edit-profile/FormOrganisasi";
import FormAlamat from "../../../containers/edit-profile/FormAlamat";
import FormKeluarga from "../../../containers/edit-profile/FormKeluarga";
import FormPendidikan from "../../../containers/edit-profile/FormPendidikan";
import FormSertifikasiProficiency from "../../../containers/edit-profile/FormSertifikasiProficiency";
import FormSertifikasiCompetency from "../../../containers/edit-profile/FormSertifikasiCompetency";
import FormPekerjaan from "../../../containers/edit-profile/FormPekerjaan";

import FormTravelDocument from "../../../containers/edit-profile/FormTravelDocument";
import FormSailExperience from "../../../containers/edit-profile/FormSailExperience";
import FormDocumentChecklist from "../../../containers/edit-profile/FormDocumentChecklist";
import {
  getBankPublic,
  getPositionPublic,
} from "../../../redux/modules/public.slice";
import { getGolonganDarah } from "../../../redux/modules/profile.slice";
import PdfLink from "../../../helper/PDFLink";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { isSubmitting } = useSelector((state) => state.profile);

  const navigate = useNavigate();

  const [photo, setPhoto] = useState({
    photo_preview: null,
    photo_file: null,
  });
  const [cv, setCv] = useState(null);

  const [activeTab, setActiveTab] = useState("data-diri");

  const [listPosition, setPositionOptions] = useState([]);
  const [listBank, setBankOptions] = useState([]);
  const [listGolonganDarah, setGolonganOptions] = useState([]);

  const handleFileChange = (event, dispatch, key) => {
		const file = event.target.files[0];

		if(key === 'photo'){
			if (file) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					dispatch({ photo_file: file, photo_preview: reader.result });
				};
			}
		}else {
			dispatch(file);
		}		
	};

  useEffect(() => {
    const fetchListPosition = async () => {
      const res = await dispatch(getPositionPublic());

      if (res.payload.status === true) {
        let tempArr = [];
        res.payload.data.map((val) => {
          tempArr.push({
            value: val.id_position,
            text: val.name_position,
          });
        });

        setPositionOptions(tempArr);
      }
    }; 
    
    const fetchListGolonganDarah = async () => {
      const res = await dispatch(getGolonganDarah());

      if (res.payload.status === true) {
        let tempArr = [];
        res.payload.data.data.map((val) => {
          tempArr.push({
            value: val.id,
            text: val.name,
          });
        });

        setGolonganOptions(tempArr);
      }
    };

    const fetchListBank = async () => {
      const res = await dispatch(getBankPublic());

      let tempArr = [];
      res.payload.map((val) => {
        tempArr.push({
          value: val.id,
          text: `${val.name} (${val.alias})`,
        });
      });

      setBankOptions(tempArr);
    };
    fetchListPosition();
    fetchListBank();
    fetchListGolonganDarah();
  }, []);

  return (
    <>
      <div className="container  mx-auto max-w-7xl ">
        <button
          className="button-submit flex mb-2 bg-slate-500 px-4"
          onClick={() => navigate("/")}
        >
          {" "}
          <ArrowLeftCircleIcon className="icon-style mr-2" /> Kembali
        </button>

      
        <div className="flex flex-col gap-4 card mb-4">
          {
          activeTab === "data-diri" && 
          <div className=" rounded-full  flex flex-col mx-auto row-span-1  items-center justify-center gap-2 mb-4">
            <img
              src={
                photo?.photo_preview
                  ? photo?.photo_preview
                  : userData?.personal_data?.photo 
                  ? userData?.personal_data?.photo_url 
                  : userData?.personal_data?.gender === "Male"
                  ? userMale
                  : userFemale
              }
              className="w-[12rem] h-[12rem] rounded-full border"
              alt=""
            />
            <input
              type="file"
              accept="image/*"
              className="input-text"
              onChange={e => handleFileChange(e, setPhoto, 'photo')}
            />
            <small className="text-slate-400">
              *Foto tidak boleh lebih dari 600Kb
            </small>
            <strong className="text-start text-black">CV Upload</strong>
						<input
							type="file"
							className="input-text"
							name='cv'
							onChange={e => handleFileChange(e, setCv, 'cv')}
							accept="image/*, application/*"
							placeholder="File CV..."
						/>
            {userData?.personal_data?.cv_file && <PdfLink url={userData?.personal_data?.cv_file_url} />}
          </div>
          }
          <div className="row-span-3">
            <div className="grid grid-cols-1">
              {/* navigation tabs */}
              <div className="border-b border-gray-200 dark:border-gray-700 mb-[2rem]">
                <ul className="flex  flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "data-diri"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("data-diri")}
                    >
                      {" "}
                      <UserGroupIcon className="icon-style mr-2" />
                      Data Diri
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "alamat"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("alamat")}
                    >
                      {" "}
                      <UserGroupIcon className="icon-style mr-2" />
                      Alamat
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "keluarga"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("keluarga")}
                    >
                      {" "}
                      <UserGroupIcon className="icon-style mr-2" />
                      Keluarga
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "sertifikasi-competency"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("sertifikasi-competency")}
                    >
                      {" "}
                      <DocumentDuplicateIcon className="icon-style mr-2" />
                      Sertifikasi Kompetensi
                    </button>
                  </li>

                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "sertifikasi-proficiency"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("sertifikasi-proficiency")}
                    >
                      {" "}
                      <DocumentDuplicateIcon className="icon-style mr-2" />
                      Sertifikasi Profesi
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "pekerjaan"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("pekerjaan")}
                    >
                      {" "}
                      <BriefcaseIcon className="icon-style mr-2" />
                      Pengalaman Berlayar
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "travel-document"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("travel-document")}
                    >
                      {" "}
                      <DocumentTextIcon className="icon-style mr-2" />
                      Dokumen Perjalanan
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                        activeTab === "dokumen-penting"
                          ? "text-[#29B6AE]  border-[#29B6AE]"
                          : " text-slate-400 border-slate-400"
                      } `}
                      onClick={() => setActiveTab("dokumen-penting")}
                    >
                      <DocumentCheckIcon className="icon-style mr-2" />
                      Dokumen Pendukung
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 card">
          {/* content tabs */}
          {activeTab === "data-diri" && (
            <div>
              <FormDataDiri
                data={userData?.personal_data}
                email={userData?.email}
                isSubmitting={isSubmitting}
                photo={photo?.photo_file}
                listPosition={listPosition}
                listBank={listBank}
                listGolonganDarah={listGolonganDarah}
                cv={cv}
              />
            </div>
          )}
          {activeTab === "alamat" && (
            <div>
              <FormAlamat
                domisili={userData?.alamat_domisili?.[0]}
                ktp={userData?.alamat_ktp?.[0]}
                isSubmitting={isSubmitting}
              />
            </div>
          )}
          {activeTab === "keluarga" && (
            <div>
              <FormKeluarga
                data={userData?.family}
                isSubmitting={isSubmitting}
              />
            </div>
          )}

          {activeTab === "pendidikan" && (
            <div>
              <FormPendidikan
                data={userData?.pendidikan}
                isSubmitting={isSubmitting}
              />
            </div>
          )}

          {activeTab === "sertifikasi-proficiency" && (
            <div>
              <FormSertifikasiProficiency
                data={userData?.certificate_proficiency}
                isSubmitting={isSubmitting}
              />
            </div>
          )}
          {activeTab === "sertifikasi-competency" && (
            <div>
              <FormSertifikasiCompetency
                data={userData?.certificate_competency}
                isSubmitting={isSubmitting}
              />
            </div>
          )}
          {activeTab === "pekerjaan" && (
            <div>
              <FormPekerjaan
                data={userData?.pekerjaan}
                isSubmitting={isSubmitting}
              />
            </div>
          )}

          {activeTab === "sail-experience" && (
            <div>
              <FormSailExperience
                data={userData?.sail_experience}
                isSubmitting={isSubmitting}
              />
            </div>
          )}

          {activeTab === "travel-document" && (
            <div>
              <FormTravelDocument
                data={userData?.travel_document}
                isSubmitting={isSubmitting}
              />
            </div>
          )}

          {activeTab === "dokumen-penting" && (
            <div>
              <FormDocumentChecklist
                data={userData?.support_document}
                isSubmitting={isSubmitting}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditProfile;

import React, { useState } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfileUser, login } from "../../redux/auth.slice";
import boat from "../../assets/boat-bg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik, Form } from "formik";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const Login = () => {
  const initialState = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { submitting } = useSelector((state) => state.user);

  const [isShow, setIsShow] = useState(false);

  const validationLogin = Yup.object().shape({
    email: Yup.string()
      .email("Email Address tidak valid.")
      .required("Form ini harus terisi."),
    password: Yup.mixed().required("Form ini harus terisi."),
  });

  const onSubmit = async (values) => {

    const res = await dispatch(login(values));

    if (res.type === "user-login/fulfilled") {
      await dispatch(getProfileUser());
      navigate("/");
    }
  };

  return (
    <section className="h-screen w-screen flex justify-center items-center bg-transparent z-10">
      <div className="px-6 h-full w-full text-gray-800">
        <div className="flex flex-col  justify-center items-center h-full">
          <div className="w-auto items-center justify-center mb-12 md:mb-0 ">
            <img src={boat} className="w-[30rem]" alt="boat" />
          </div>
      
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            {/* <button
              className="button-submit text-center items-center flex w-full bg-red-500 my-2 hover:bg-red-800"
              onClick={() => navigate("/cv")}
            >
              {" "}
              <UserPlusIcon className="w-5 h-5 mr-2" /> <span>Collect data for crew</span>
            </button> */}
            <div className="my-2">
              <h1 className="text-xl font-semibold md:text-2xl text-black">
                Login Candidate
              </h1>
            </div>
            <Formik
              initialValues={initialState}
              onSubmit={onSubmit}
              validationSchema={validationLogin}
            >
              {({
                errors,
                touched,
                values,
                setFieldTouched,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="mb-3">
                    <label>Email</label>
                    <Field
                      className="input-text"
                      name="email"
                      value={values?.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Email..."
                    />
                    {errors.email && touched.email ? (
                      <div className="d-block text-danger">*{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label>Password</label>

                    <Field
                      type={isShow ? `text` : `password`}
                      className="input-text"
                      name="password"
                      value={values?.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Password..."
                    />
                    {isShow ? (
                      <div className="items-center gap-2 justify-between flex">
                        <div
                          className="items-center gap-2 py-2 flex  text-[#29B6AE] flex-wrap"
                      
                        >
                          <EyeSlashIcon className="icon-style cursor-pointer" onClick={() => setIsShow(!isShow)} />
                          <small>Hide password</small>
                        </div>
                        <div
                          className="items-center gap-2 py-2 flex cursor-pointer text-[#29B6AE] flex-wrap"
                        >
                          <small onClick={() => navigate('/forgot-password')}>Forget password?</small>
                        </div>
                      </div>
                   
                    ) : (
                    
                    <div className="items-center gap-2 justify-between flex">
                       <div
                         className="items-center gap-2 py-2 flex  text-[#29B6AE] flex-wrap"
                     
                       >
                        <EyeIcon className="icon-style cursor-pointer"  onClick={() => setIsShow(!isShow)} />
                        <small>Show password</small>
                       </div>
                       <div
                         className="items-center gap-2 py-2 flex cursor-pointer text-[#146C94] flex-wrap"
                       >
                         <small onClick={() => navigate('/forgot-password')}>Forget password?</small>
                       </div>
                     </div>
                    )}

                    {errors.password && touched.password ? (
                      <div className="d-block text-danger">
                        *{errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className="text-center lg:text-left">
                    <button type="submit" className="button-submit">
                      {submitting ? (
                        <FontAwesomeIcon
                          icon="spinner"
                          size="xl"
                          className="text-white animate-spin"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                    <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                      Didn't have an account?{" "}
                      <NavLink to="/register">
                        <span className="text-yellow-600 cursor-pointer">
                          Register Now!
                        </span>
                      </NavLink>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

import React from "react";

const TablePekerjaan = ({ data }) => {
  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Perusahaan
            </th>
            <th scope="col" className="px-6 py-3">
              Negara
            </th>
            <th scope="col" className="px-6 py-3">
              Lokasi
            </th>
            <th scope="col" className="px-6 py-3">
              Nama Kapal
            </th>
            <th scope="col" className="px-6 py-3">
              Bendera Kapal
            </th>
            <th scope="col" className="px-6 py-3">
              Area Operasi
            </th>
            <th scope="col" className="px-6 py-3">
              Tahun Masuk - Tahun Berhenti
            </th>
            <th scope="col" className="px-6 py-3">
              Gaji
            </th>
            <th scope="col" className="px-6 py-3">
              Jabatan
            </th>

            <th scope="col" className="px-6 py-3">
              Deskripsi Pekerjaan
            </th>
            <th scope="col" className="px-6 py-3">
              Fasilitas
            </th>
            <th scope="col" className="px-6 py-3">
              Pemberi Rekomendasi
            </th>
            <th scope="col" className="px-6 py-3">
              Kontak Pemberi Rekomendasi
            </th>
            <th scope="col" className="px-6 py-3">
              No Perusahaan
            </th>
            <th scope="col" className="px-6 py-3">
              Alasan Berhenti
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={index}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {val?.perusahaan ?? "-"}
              </th>
              <td className="px-6 py-4"> {val?.negara ?? "-"}</td>
              <td className="px-6 py-4">{val?.lokasi ?? "-"}</td>

              <td className="px-6 py-4">{val?.nama_kapal ?? "-"}</td>
              <td className="px-6 py-4">{val?.bendera_kapal ?? "-"}</td>
              <td className="px-6 py-4">{val?.area_operasi ?? "-"}</td>

              <td className="px-6 py-4">
                {val?.tahun_masuk ?? "-"}{" "}
                <span className="text-xs text-gray-400">s/d</span>{" "}
                {val?.tahun_berhenti ?? "-"}
              </td>
              <td className="px-6 py-4">{val?.gaji ?? "-"}</td>
              <td className="px-6 py-4">{val?.jabatan ?? "-"}</td>
              <td className="px-6 py-4">{val?.deskripsi_pekerjaan ?? "-"}</td>
              <td className="px-6 py-4">{val?.fasilitas ?? "-"}</td>
              <td className="px-6 py-4">{val?.pemberi_rekomendasi ?? "-"}</td>
              <td className="px-6 py-4">
                {val?.kontak_pemberi_informasi ?? "-"}
              </td>
              <td className="px-6 py-4">{val?.no_tlp_perusahaan ?? "-"}</td>
              <td className="px-6 py-4">{val?.alasan_berhenti ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePekerjaan;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteKeluarga,
  setAlamat,
  setDataDiri,
  setKeluarga,
} from "../../redux/modules/profile.slice";
import CountAge from "../../helper/CountAge";
import { getProfileUser, updateFamily } from "../../redux/auth.slice";
import { setKeluargaCrew } from "../../services/CVStorage";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import { collectDataKeluarga } from "../../redux/modules/collect.slice";

const optionsHubungan = [
  { value: "Bapak", text: "Bapak" },
  { value: "Ibu", text: "Ibu" },
  { value: "Suami", text: "Suami" },
  { value: "Istri", text: "Istri" },
  { value: "Anak", text: "Anak" },
  { value: "Saudara Kandung", text: "Saudara Kandung" },
  { value: "Kakak", text: "Kakak" },
  { value: "Adik", text: "Adik" },
];

const FormKeluarga = ({ isSubmitting, data, isPublic, next, prev }) => {
  const initialState = {
    keluarga:
      data?.length > 0
        ? data
        : [
            {
              status: "",
              nama_lengkap: "",
              tempat_lahir: "",
              tanggal_lahir: "",
              jenis_kelamin: "",
              pekerjaan: "",
              alamat: "",
              no_hp: "",
              age_family: null,
            },
          ],
  };
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  const validationKeluarga = Yup.object().shape({
    keluarga: Yup.array().of(
      Yup.object().shape({
        status: Yup.mixed().required("Form ini harus terisi."),
        nama_lengkap: Yup.mixed().required("Form ini harus terisi."),
        tempat_lahir: Yup.mixed().required("Form ini harus terisi."),
        tanggal_lahir: Yup.date().required("Form ini harus terisi."),
        jenis_kelamin: Yup.mixed().required("Form ini harus terisi."),
        pekerjaan: Yup.mixed().required("Form ini harus terisi."),
        alamat: Yup.mixed().required("Form ini harus terisi."),

        age_family: Yup.mixed().required("Form ini harus terisi."),
      })
    ),
  });

  const handleChangeAge = (e, index, setFieldValue) => {
    let newAge = CountAge(e.target.value);

    setFieldValue(`keluarga[${index}].tanggal_lahir`, e.target.value);
    setFieldValue(`keluarga[${index}].age_family`, newAge);
  };

  const eventAddData = (values, setFieldValue) => {
    let newArr = [...values.keluarga];
    newArr.push({
      status: "",
      nama_lengkap: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jenis_kelamin: "",
      pekerjaan: "",
      alamat: "",
      no_hp: "",
      age_family: "",
    });
    setFieldValue("keluarga", newArr);
  };

  const onDelete = (values, index, setFieldValue) => {
      if(!values.keluarga[index].id){
        return  setFieldValue(
          "keluarga",
          values.keluarga.filter((_, i) => i !== index)
        )
      }

      Swal.fire({
        title: 'Apakah yakin ingin menghapus data ini?',
        text: "Data yang terhapus tidak akan kembali.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
      }).then(async (result) => {
        if (result.isConfirmed) {

          const res = await dispatch(deleteKeluarga(values.keluarga[index].id))

          if(res.payload.status === true){
            setFieldValue(
              "keluarga",
              values.keluarga.filter((_, i) => i !== index)
            )
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
        }
      })
  }

  const onSubmit = async (values) => {
    if (isPublic) {
      setLoading(true)
      await setKeluargaCrew(values);

      let formData = new FormData()

      values?.keluarga?.forEach((item, index) => {
        for (const property in item) {
          formData.append(`keluarga[${index}][${property}]`, item[property]);
        }
      });

      let user_id = localStorage.getItem('user_id')

      const res = await dispatch(collectDataKeluarga({id: Number(user_id), data:formData}))

      if(res.payload.data.status === false || res.payload.status === 400){
        setLoading(false)
        toast.error(res.payload.data.data);
        return 
      }
      toast.success('Simpan data keluarga berhasil.');
      setLoading(false)

      return next();
    }

    let newArr = []
    values.keluarga.map((val) => {
      newArr.push({
        ...val,
        age_family: val.age_family.toString()
      })
    })

    const res = await dispatch(setKeluarga({keluarga: newArr}));

    if (res.payload.status === true) {
      dispatch(updateFamily(values.keluarga));
    }
  };

  return (
    <>
      <div className="flex mb-4">
        <div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
          <h1 className="text-xl text-white font-bold ">Biodata Keluarga</h1>
        </div>
      </div>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validationSchema={validationKeluarga}
      >
        {({
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="text-sm">
            {values?.keluarga?.map((val, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
                key={index}
              >
                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Nama Lengkap</label>
                  <Field
                    className="input-text  "
                    name={`keluarga[${index}].nama_lengkap`}
                    id={`keluarga[${index}].nama_lengkap`}
                    value={values?.keluarga?.[index]?.nama_lengkap}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `keluarga[${index}].nama_lengkap`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama lengkap..."
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].nama_lengkap`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Hubungan</label>
                  <select
                    className="input-text"
                    name={`keluarga[${index}].status`}
                    id={`keluarga[${index}].status`}
                    onBlur={handleBlur}
                    value={values?.keluarga?.[index]?.status}
                    onChange={(e) => {
                      setFieldValue(
                        `keluarga[${index}].status`,
                        e.target.value
                      );
                    }}
                    placeholder="Hubungan keluarga..."
                  >
                    <option value="">Select Options...</option>
                    {optionsHubungan.map((val) => (
                      <option value={val.value}>{val.text}</option>
                    ))}
                  </select>
                  <ErrorMessage
                    name={`keluarga[${index}].status`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Tempat Lahir</label>
                  <Field
                    className="input-text  "
                    name={`keluarga[${index}].tempat_lahir`}
                    id={`keluarga[${index}].tempat_lahir`}
                    onBlur={handleBlur}
                    value={values?.keluarga?.[index]?.tempat_lahir}
                    onChange={(e) => {
                      setFieldValue(
                        `keluarga[${index}].tempat_lahir`,
                        e.target.value
                      );
                    }}
                    placeholder="Tempat lahir..."
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].tempat_lahir`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Tanggal Lahir</label>
                  <Field
                    type="date"
                    className="input-text  "
                    name={`keluarga[${index}].tanggal_lahir`}
                    id={`keluarga[${index}].tanggal_lahir`}
                    value={values?.keluarga?.[index]?.tanggal_lahir}
                    onBlur={handleBlur}
                    onChange={(e) => handleChangeAge(e, index, setFieldValue)}
                    placeholder="Tanggal lahir..."
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].tanggal_lahir`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Umur</label>
                  <Field
                    type="number"
                    disabled
                    className="input-text  "
                    name={`keluarga[${index}].age_family`}
                    id={`keluarga[${index}].age_family`}
                    onBlur={handleBlur}
                    value={values?.keluarga?.[index]?.age_family}
                    placeholder="Pilih tanggal lahir..."
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].age_family`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 md:col-span-4 col-span-6">
                  <label>Jenis Kelamin</label>
                  <select
                    className="input-text"
                    name={`keluarga[${index}].jenis_kelamin`}
                    id={`keluarga[${index}].jenis_kelamin`}
                    onBlur={handleBlur}
                    value={values?.keluarga?.[index]?.jenis_kelamin}
                    onChange={(e) => {
                      setFieldValue(
                        `keluarga[${index}].jenis_kelamin`,
                        e.target.value
                      );
                    }}
                    placeholder="Jenis Kelamin..."
                  >
                    <option value="">Select Options...</option>
                    <option value="L">Laki - laki</option>
                    <option value="P">Perempuan</option>
                  </select>
                  <ErrorMessage
                    name={`keluarga[${index}].jenis_kelamin`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-6 sm:col-span-4">
                  <label>Pekerjaan</label>
                  <Field
                    className="input-text  "
                    name={`keluarga[${index}].pekerjaan`}
                    id={`keluarga[${index}].pekerjaan`}
                    value={values?.keluarga?.[index]?.pekerjaan}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `keluarga[${index}].pekerjaan`,
                        e.target.value
                      );
                    }}
                    placeholder="Pekerjaan..."
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].pekerjaan`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-6 sm:col-span-4">
                  <label>No Handphone</label>
                  <Field
                    type="number"
                    className="input-text  "
                    name={`keluarga[${index}].no_hp`}
                    id={`keluarga[${index}].no_hp`}
                    value={values?.keluarga?.[index]?.no_hp}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`keluarga[${index}].no_hp`, e.target.value);
                    }}
                    placeholder="No Hanphone..."
                  />
                </div>

                <div className="mb-3 col-span-9 sm:col-span-4">
                  <label>Alamat Lengkap</label>
                  <Field
                    type="text"
                    component="textarea"
                    rows={4}
                    className="input-text  "
                    name={`keluarga[${index}].alamat`}
                    id={`keluarga[${index}].alamat`}
                    value={values?.keluarga?.[index]?.alamat}
                    placeholder="Alamat lengkap..."
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={`keluarga[${index}].alamat`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                {index === 0 ? (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Tambah</label>
                    <button
                      type="button"
                      className="button-submit block mb-4"
                      onClick={() => eventAddData(values, setFieldValue)}
                    >
                      <FontAwesomeIcon
                        icon="plus"
                        size="lg"
                        className="text-white "
                      />
                    </button>
                  </div>
                ) : (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Hapus</label>
                    <button
                      type="button"
                      className="button-submit block mb-4 bg-red-500"
                      onClick={() =>
                       onDelete(values, index, setFieldValue)
                      }
                    >
                      <FontAwesomeIcon
                        icon="trash"
                        size="lg"
                        className="text-white"
                      />
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className="text-center lg:text-left flex mt-4">
              {isPublic && (
                <button
                  type="button"
                  className="button-submit block mr-2"
                  onClick={() => {
                    prev();
                  }}
                >
                  {`<`} Prev
                </button>
              )}

              <button type="submit" className="button-submit block">
                {isSubmitting || loading ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    size="xl"
                    className="text-white animate-spin"
                  />
                ) : isPublic ? (
                  "Simpan >"
                ) : (
                  "Save Data"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormKeluarga;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setPendidikan } from "../../redux/modules/profile.slice";
import { updatePendidikan } from "../../redux/auth.slice";

const optionsJenjang = [
  { value: "1", text: "ATT I" },
  { value: "2", text: "ATT II" },
  { value: "3", text: "ATT III" },
  { value: "4", text: "ATT IV" },
  { value: "5", text: "ATT V" },
  { value: "6", text: "ANT I" },
  { value: "7", text: "ANT II" },
  { value: "8", text: "ANT III" },
  { value: "9", text: "ANT IV" },
   { value: "10", text: "ANT V" },
];

const FormPendidikan = ({ isSubmitting, data }) => {
  const initialState = {
    pendidikan:
      data.length > 0
        ? data
        : [
            {
              jenjang: "",
              sekolah: "",
              management: "",
              tahun_mulai: "",
              tahun_selesai: "",
              kota: "",
              negara: "",
            },
          ],
  };
  const dispatch = useDispatch();

  const validationPendidikan = Yup.object().shape({
    pendidikan: Yup.array().of(
      Yup.object().shape({
        jenjang: Yup.mixed().required("Form ini harus terisi."),
        sekolah: Yup.mixed().required("Form ini harus terisi."),
        management: Yup.mixed().required("Form ini harus terisi."),
        tahun_mulai: Yup.mixed().required("Form ini harus terisi."),
        tahun_selesai: Yup.mixed().required("Form ini harus terisi."),
        kota: Yup.mixed().required("Form ini harus terisi."),
        negara: Yup.mixed().required("Form ini harus terisi."),
      })
    ),
  });

  const eventAddData = (values, setFieldValue) => {
    let newArr = [...values.pendidikan];
    newArr.push({
      jenjang: "",
      sekolah: "",
      management: "",
      tahun_mulai: "",
      tahun_selesai: "",
      kota: "",
      negara: "",
    });
    setFieldValue("pendidikan", newArr);
  };

  const onSubmit = async (values) => {

    const res = await dispatch(setPendidikan(values));

    if (res.payload.status === true) {
      dispatch(updatePendidikan(values.pendidikan));
    }
  };

  return (
    <>
      <div className="flex mb-4">
        <div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
          <h1 className="text-xl text-white font-bold ">Form Pendidikan</h1>
        </div>
      </div>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validationSchema={validationPendidikan}
      >
        {({
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="text-sm">
            {values?.pendidikan?.map((val, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
                key={index}
              >
                <div className="mb-3 col-span-4">
                  <label>Nama Sekolah / Universitas</label>
                  <Field
                    className="input-text  "
                    name={`pendidikan[${index}].sekolah`}
                    id={`pendidikan[${index}].sekolah`}
                    value={values?.pendidikan?.[index]?.sekolah}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].sekolah`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama sekolah..."
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].sekolah`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-4">
                  <label>Nama Negara</label>
                  <Field
                    className="input-text  "
                    name={`pendidikan[${index}].negara`}
                    id={`pendidikan[${index}].negara`}
                    value={values?.pendidikan?.[index]?.negara}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].negara`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama negara..."
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].negara`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-4">
                  <label>Nama Kota</label>
                  <Field
                    className="input-text  "
                    name={`pendidikan[${index}].kota`}
                    id={`pendidikan[${index}].kota`}
                    value={values?.pendidikan?.[index]?.kota}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].kota`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama kota..."
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].kota`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-6">
                  <label>Jenjang</label>
                  <select
                    className="input-text  "
                    name={`pendidikan[${index}].jenjang`}
                    id={`pendidikan[${index}].jenjang`}
                    value={values?.pendidikan?.[index]?.jenjang}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].jenjang`,
                        e.target.value
                      );
                    }}
                  >
                    <option value="">Select Jenjang...</option>
                    {optionsJenjang.map((val, index) => (
                      <option key={index} value={val.value}>
                        {val.text}
                      </option>
                    ))}
                  </select>

                  <ErrorMessage
                    name={`pendidikan[${index}].status`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-6">
                  <label>Management</label>
                  <Field
                    className="input-text  "
                    name={`pendidikan[${index}].management`}
                    id={`pendidikan[${index}].management`}
                    value={values?.pendidikan?.[index]?.management}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].management`,
                        e.target.value
                      );
                    }}
                    placeholder="Management..."
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].management`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                {/* <div className="mb-3 col-span-4">
                  <label>IPK / Nilai Akhir</label>
                  <Field
                    type="number"
                    className="input-text  "
                    name={`pendidikan[${index}].nilai`}
                    id={`pendidikan[${index}].nilai`}
                    value={values?.pendidikan?.[index]?.nilai}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].nilai`,
                        e.target.value
                      );
                    }}
                    placeholder="IPK..."
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].nilai`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div> */}
                <div className="mb-3 col-span-5">
                  <label>Tahun Mulai</label>
                  <Field
                    type="number"
                    className="input-text  "
                    name={`pendidikan[${index}].tahun_mulai`}
                    id={`pendidikan[${index}].tahun_mulai`}
                    value={values?.pendidikan?.[index]?.tahun_mulai}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].tahun_mulai`,
                        e.target.value
                      );
                    }}
                    placeholder="YYYY"
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].tahun_mulai`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-6">
                  <label>Tahun Selesai</label>
                  <Field
                    type="number"
                    className="input-text  "
                    name={`pendidikan[${index}].tahun_selesai`}
                    id={`pendidikan[${index}].tahun_selesai`}
                    value={values?.pendidikan?.[index]?.tahun_selesai}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `pendidikan[${index}].tahun_selesai`,
                        e.target.value
                      );
                    }}
                    placeholder="YYYY"
                  />
                  <ErrorMessage
                    name={`pendidikan[${index}].tahun_selesai`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                {index === 0 ? (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Tambah</label>
                    <button
                      type="button"
                      className="button-submit block mb-4"
                      onClick={() => eventAddData(values, setFieldValue)}
                    >
                      <FontAwesomeIcon
                        icon="plus"
                        size="lg"
                        className="text-white "
                      />
                    </button>
                  </div>
                ) : (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Hapus</label>
                    <button
                      type="button"
                      className="button-submit block mb-4 bg-red-500"
                      onClick={() =>
                        setFieldValue(
                          "pendidikan",
                          values.pendidikan.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon="trash"
                        size="lg"
                        className="text-white"
                      />
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className="text-center lg:text-left">
              <button type="submit" className="button-submit block">
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    size="xl"
                    className="text-white animate-spin"
                  />
                ) : (
                  "Save Data"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPendidikan;

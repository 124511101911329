import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "../views/app/dashboard";

import Login from "../views/user/login";
import Register from "../views/user/register";
import AppLayout from "../layout/AppLayout";
import UserLayout from "../layout/UserLayout";
import appRoutes from "./app.routes";
import publicRoutes from "./public.routes";
import ForgetPassword from "../views/app/forget-password";

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <AppLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      ...appRoutes,
    ],
  },
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgetPassword />,
      },
    ],
  },
  // {
  //   path: "/",
  //   children: [...publicRoutes],
  // },
  {
    path: "*",
    element: <>NOT FOUND PAGE</>,
  },
];

export default routes;

import React, { Suspense, useEffect, useState } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import routes from "./router";
import { useDispatch, useSelector } from "react-redux";
import { getProfileUser, logout } from "./redux/auth.slice";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartSimple,
  faUser,
  faSearch,
  faReceipt,
  faLandMineOn,
  faRightFromBracket,
  faSpinner,
  faFilter,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notifyError } from "./components/notification/Toast";

function App() {
  library.add(
    faChartSimple,
    faUser,
    faSearch,
    faReceipt,
    faLandMineOn,
    faRightFromBracket,
    faSpinner,
    faFilter,
    faPlus,
    faTrash
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, loading } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchMyProfile = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        const res = await dispatch(getProfileUser());
        if (res?.payload?.status === true) {
          if (location.pathname === "/login") {
            navigate(`/`);
          } else {
            navigate(`${location.pathname}`);
          }
        } else {
          dispatch(logout());
          localStorage.clear();
          navigate("/login");
          // notifyError("Mohon login Kembali.");
        }
      } else if (location.pathname === "/" && !access_token) {
        navigate(`/login`);
      }
    };

    fetchMyProfile();
  }, [dispatch]);

  const routing = useRoutes(routes(isLoggedIn));

  return loading ? (
    <div className="h-screen w-screen flex justify-center items-center">
      <FontAwesomeIcon
        icon="spinner"
        size="4x"
        className="text-slate-900 animate-spin"
      />
    </div>
  ) : (
    <div className="w-full h-full">
      <Toaster />
      <Suspense
        fallback={
          <FontAwesomeIcon
            icon="spinner"
            size="2x"
            className="text-slate-900 animate-spin"
          />
        }
      >
        {routing}
      </Suspense>
    </div>
  );
}

export default App;

import axiosAPI from "../../services/Axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { notifyError } from "../../components/notification/Toast";
import { SwalSuccess } from "../../components/notification/Swal";

const BASE_PORTAL_CV = `${process.env.REACT_APP_BASE_PORTAL}`;
const BASE_URL = `${process.env.REACT_APP_BASE_PORTAL}/recruitment/dashboard/cv`;
const BASE_CREWING = `${process.env.REACT_APP_BASE_URL}`;

const initialState = {
  loading: false,
};

// Generates pending, fulfilled and rejected action types

export const getProvinsiPublic = createAsyncThunk(`get-provinsi`, async () => {
  return await axiosAPI
    .get(`${BASE_PORTAL_CV}/provinces`)
    .then((response) => response.data)
    .catch((err) => err);
});

export const getKabupatenPublic = createAsyncThunk(
  `get-kabupaten`,
  async (data) => {
    return await axiosAPI
      .post(`${BASE_PORTAL_CV}/cities`, data)
      .then((response) => response.data)
      .catch((err) => err);
  }
);

export const getKecamatanPublic = createAsyncThunk(
  `get-kecamatan`,
  async (data) => {
    return await axiosAPI
      .post(`${BASE_PORTAL_CV}/districts`, data)
      .then((response) => response.data)
      .catch((err) => err);
  }
);

export const getKelurahanPublic = createAsyncThunk(
  `get-kelurahan`,
  async (data) => {
    return await axiosAPI
      .post(`${BASE_PORTAL_CV}/villages`, data)
      .then((response) => response.data)
      .catch((err) => err);
  }
);

// ============================

export const getPositionPublic = createAsyncThunk(
  `get-list-position-public`,
  async () => {
    const url = `${BASE_PORTAL_CV}/list-position-public`;
    return await axiosAPI.get(`${url}`).then((response) => response.data);
  }
);
export const getEducations = createAsyncThunk(
  `get-list-educations-public`,
  async () => {
    const url = `${BASE_PORTAL_CV}/educations`;
    return await axiosAPI.get(`${url}`).then((response) => response.data);
  }
);
export const getCertificateList = createAsyncThunk(
  `get-list-certificate-public`,
  async (params) => {
    const url = `${BASE_PORTAL_CV}/certificate-list`;
    return await axiosAPI
      .get(`${url}`, { params })
      .then((response) => response.data);
  }
);

export const getBankPublic = createAsyncThunk(
  `get-list-bank-public`,
  async () => {
    const url = `${BASE_PORTAL_CV}/banks`;
    return await axiosAPI.get(`${url}`).then((response) => response.data);
  }
);

export const getVehiclePublic = createAsyncThunk(
  `get-list-vehicle-public`,
  async () => {
    const url = `${BASE_PORTAL_CV}/list-vehicle-public`;
    return await axiosAPI.get(`${url}`).then((response) => response.data);
  }
);

export const getGolonganDarahPublic = createAsyncThunk(
  `get-list-golongan-darah`,
  async () => {
    const url = `${BASE_PORTAL_CV}/golongan-darah-public`;
    return await axiosAPI.get(`${url}`).then((response) => response.data);
  }
);

// ------------------------------------------------------

export const setDataDiriPublic = createAsyncThunk(
  `set-datadiri-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-data-diri-public`;
      return await axiosAPI
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const collectDataCrewPublic = createAsyncThunk(
  `collect-data`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/collect-data-crew`;
      return await axiosAPI
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setDocumentChecklistPublic = createAsyncThunk(
  `set-create-or-update-dokumen-pendukung-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-dokumen-pendukung-public`;
      return await axiosAPI
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setAlamatPublic = createAsyncThunk(
  `set-alamat-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-alamat-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setKeluargaPublic = createAsyncThunk(
  `set-keluarga-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-keluarga-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setOrganisasiPublic = createAsyncThunk(
  `set-organisasi-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-organisasi-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setPekerjaanPublic = createAsyncThunk(
  `set-pekerjaan-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-pekerjaan-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setTravelDocumentPublic = createAsyncThunk(
  `set-create-or-update-travel-dokumen-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-travel-dokumen-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setSertifikasiProficiencyPublic = createAsyncThunk(
  `set-create-or-update-proficiency-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-proficiency-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setSertifikasiCompetencyPublic = createAsyncThunk(
  `set-create-or-update-competency-public`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-competency-public`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default publicSlice.reducer;

import React from "react";

function PdfLink(props) {
  const { url } = props;

  const handleClick = (e) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  return (
    <a
      href={url}
      onClick={handleClick}
      className="text-blue-400 hover:text-blue-800 cursor-pointer"
    >
      Open File
    </a>
  );
}

export default PdfLink;

export const setBiodata = (data) => {
  try {
    if (data) {
      localStorage.setItem("biodata", JSON.stringify(data));
    } else {
      localStorage.removeItem("biodata");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getBiodata = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("biodata") != null
        ? JSON.parse(localStorage.getItem("biodata"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setAlamatCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("alamat", JSON.stringify(data));
    } else {
      localStorage.removeItem("alamat");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getAlamatCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("alamat") != null
        ? JSON.parse(localStorage.getItem("alamat"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setKeluargaCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("keluarga", JSON.stringify(data));
    } else {
      localStorage.removeItem("keluarga");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getKeluargaCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("keluarga") != null
        ? JSON.parse(localStorage.getItem("keluarga"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setKompetensiCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("kompetensi", JSON.stringify(data));
    } else {
      localStorage.removeItem("kompetensi");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getKompetensiCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("kompetensi") != null
        ? JSON.parse(localStorage.getItem("kompetensi"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setProfesiCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("profesi", JSON.stringify(data));
    } else {
      localStorage.removeItem("profesi");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getProfesiCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("profesi") != null
        ? JSON.parse(localStorage.getItem("profesi"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setOrganisasiCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("organisasi", JSON.stringify(data));
    } else {
      localStorage.removeItem("organisasi");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getOrganisasiCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("organisasi") != null
        ? JSON.parse(localStorage.getItem("organisasi"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setBerlayarCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("pengalaman_berlayar", JSON.stringify(data));
    } else {
      localStorage.removeItem("pengalaman_berlayar");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getBerlayarCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("pengalaman_berlayar") != null
        ? JSON.parse(localStorage.getItem("pengalaman_berlayar"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setDokumenPerjalananCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("dokumen_perjalanan", JSON.stringify(data));
    } else {
      localStorage.removeItem("dokumen_perjalanan");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getDokumenPerjalananCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("dokumen_perjalanan") != null
        ? JSON.parse(localStorage.getItem("dokumen_perjalanan"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

export const setDokumenPendukungCrew = (data) => {
  try {
    if (data) {
      localStorage.setItem("dokumen_pendukung", JSON.stringify(data));
    } else {
      localStorage.removeItem("dokumen_pendukung");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
  }
};

export const getDokumenPentingCrew = () => {
  let data = null;
  try {
    data =
      localStorage.getItem("dokumen_pendukung") != null
        ? JSON.parse(localStorage.getItem("dokumen_pendukung"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js", error);
    data = null;
  }
  return data;
};

// ---------------------------------------------------

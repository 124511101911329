import axiosAPI from "../../services/Axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SwalSuccess } from "../../components/notification/Swal";
import { notifyError } from "../../components/notification/Toast";

const BASE_PORTAL_CV = `${process.env.REACT_APP_BASE_PORTAL}/recruitment/dashboard/cv`;
const BASE_PORTAL = `${process.env.REACT_APP_BASE_PORTAL}`;
const BASE_URL = `${process.env.REACT_APP_BASE_PORTAL}/recruitment/dashboard/cv`;
const BASE_CREWING = `${process.env.REACT_APP_BASE_URL}`;

const initialState = {
  isSubmitting: false,
};

export const getProvinsi = createAsyncThunk(`get-provinsi`, async () => {
  return await axiosAPI
    .get(`${BASE_PORTAL_CV}/provinces`)
    .then((response) => response.data)
    .catch((err) => err);
});

export const getGolonganDarah = createAsyncThunk(`get-golongan-darah`, async () => {
  return await axiosAPI
    .get(`${BASE_PORTAL_CV}/golongan-darah`)
    .then((response) => response.data)
    .catch((err) => err);
});

export const getKabupaten = createAsyncThunk(`get-kabupaten`, async (data) => {
  return await axiosAPI
    .post(`${BASE_PORTAL_CV}/cities`, data)
    .then((response) => response.data)
    .catch((err) => err);
});

export const getKecamatan = createAsyncThunk(`get-kecamatan`, async (data) => {
  return await axiosAPI
    .post(`${BASE_PORTAL_CV}/districts`, data)
    .then((response) => response.data)
    .catch((err) => err);
});

export const getKelurahan = createAsyncThunk(`get-kelurahan`, async (data) => {
  return await axiosAPI
    .post(`${BASE_PORTAL_CV}/villages`, data)
    .then((response) => response.data)
    .catch((err) => err);
});

// -----------------------------------------------------------------------------------

export const getListPosition = createAsyncThunk(
  `get-list-position`,
  async (params) => {
    return await axiosAPI
      .get(`${BASE_CREWING}/list-position`, { params })
      .then((response) => response.data)
      .catch((err) => err);
  }
);

export const getListVehicle = createAsyncThunk(
  `get-list-vehicle`,
  async (params) => {
    return await axiosAPI
      .get(`${BASE_URL}/vehicles`, { params })
      .then((response) => response.data)
      .catch((err) => err);
  }
);

export const getMasterDocumentList = createAsyncThunk(
  `get-master-document-list`,
  async (params) => {
    return await axiosAPI
      .get(`${BASE_PORTAL}/master-document-list`, { params })
      .then((response) => response.data)
      .catch((err) => err);
  }
);

// Generates pending, fulfilled and rejected action types
export const setDataDiri = createAsyncThunk(
  `set-datadiri`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-data-diri`;
      return await axiosAPI
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setDocumentChecklist = createAsyncThunk(
  `set-create-or-update-dokumen-pendukung`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-dokumen-pendukung`;
      return await axiosAPI
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setAlamat = createAsyncThunk(
  `set-alamat`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-alamat`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setKeluarga = createAsyncThunk(
  `set-keluarga`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-keluarga`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const setPendidikan = createAsyncThunk(
  `set-pendidikan`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-pendidikan`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const setOrganisasi = createAsyncThunk(
  `set-organisasi`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-organisasi`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setPekerjaan = createAsyncThunk(
  `set-pekerjaan`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-pekerjaan`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setTravelDocument = createAsyncThunk(
  `set-create-or-update-travel-dokumen`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-travel-dokumen`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setSertifikasiProficiency = createAsyncThunk(
  `set-create-or-update-proficiency`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-proficiency`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setSailExperience = createAsyncThunk(
  `set-create-or-update-sail-experience`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-sail-experience`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setSertifikasiCompetency = createAsyncThunk(
  `set-create-or-update-competency`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/create-or-update-competency`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteKeluarga = createAsyncThunk(
  `delete-keluarga`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-keluarga/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteOrganisasi = createAsyncThunk(
  `delete-organisasi`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-organisasi/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deletePekerjaan = createAsyncThunk(
  `delete-pekerjaan`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-pekerjaan/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteDokumenPendukung = createAsyncThunk(
  `delete-dokumen-pendukung`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-dokumen-pendukung/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteTravelDokumen = createAsyncThunk(
  `delete-travel-dokumen`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-travel-dokumen/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCompetency = createAsyncThunk(
  `delete-competency`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-competency/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteProficiency = createAsyncThunk(
  `delete-proficiency`,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/delete-proficiency/${id}`;
      return await axiosAPI
        .delete(`${url}`)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  `forgot-password-user`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/auth/forgot-password`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDataDiri.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setDataDiri.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update data diri."
      );
    });
    builder.addCase(setDataDiri.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setDocumentChecklist.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setDocumentChecklist.fulfilled, (state, action) => {
      state.isSubmitting = false;
    });
    builder.addCase(setDocumentChecklist.rejected, (state, action) => {
      state.isSubmitting = false;
    });
    // --------------------------------------------------------
    builder.addCase(setSertifikasiCompetency.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setSertifikasiCompetency.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update sertifikasi competency."
      );
    });
    builder.addCase(setSertifikasiCompetency.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setSailExperience.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setSailExperience.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update sail experience."
      );
    });
    builder.addCase(setSailExperience.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });

    // --------------------------------------------------------
    builder.addCase(setSertifikasiProficiency.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setSertifikasiProficiency.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update sertifikasi proficiency."
      );
    });
    builder.addCase(setSertifikasiProficiency.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });

    // --------------------------------------------------------
    builder.addCase(setTravelDocument.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setTravelDocument.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update travel dokumen."
      );
    });
    builder.addCase(setTravelDocument.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setPekerjaan.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setPekerjaan.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update pekerjaan."
      );
    });
    builder.addCase(setPekerjaan.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setPendidikan.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setPendidikan.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update pendidikan."
      );
    });
    builder.addCase(setPendidikan.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });

    // --------------------------------------------------------
    builder.addCase(setOrganisasi.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setOrganisasi.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update organisasi."
      );
    });
    builder.addCase(setOrganisasi.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setKeluarga.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setKeluarga.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update keluaga."
      );
    });
    builder.addCase(setKeluarga.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
    // --------------------------------------------------------
    builder.addCase(setAlamat.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(setAlamat.fulfilled, (state, action) => {
      state.isSubmitting = false;

      SwalSuccess(
        "Update Data Berhasil.",
        "User berhasil melakukan update alamat."
      );
    });
    builder.addCase(setAlamat.rejected, (state, action) => {
      state.isSubmitting = false;

      notifyError(action.payload.data.data);
    });
  },
});

export default userSlice.reducer;
export const { logout } = userSlice.actions;

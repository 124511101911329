import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserPlusIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { clearCache } from "../helper/clearCache";

const UserLayout = () => {
	const navigate = useNavigate();
	return (
		<div className="flex bg-slate-200 ">
			

			<button
				className="button-submit text-center flex absolute -rotate-90 -left-14 top-[50%] z-50 bg-orange-500 hover:bg-orange-800"
				onClick={() => clearCache()}
			>
				{" "}
				<ArrowPathIcon className="w-5 h-5 mr-2" /> Clear Cache
			</button>
			<div className=" lg:h-[75vh] md:h-[65vh] sm:h-[55vh] w-auto absolute top-0 left-0 right-0 opacity-100 -z-[10] "></div>
			<main>
				<Outlet />
			</main>
		</div>
	);
};

export default UserLayout;

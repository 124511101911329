import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  getKabupaten,
  getKecamatan,
  getKelurahan,
  getProvinsi,
  setAlamat,
} from "../../redux/modules/profile.slice";
import {
  getKabupatenPublic,
  getKecamatanPublic,
  getKelurahanPublic,
  getProvinsiPublic,
} from "../../redux/modules/public.slice";
import { getProfileUser } from "../../redux/auth.slice";
import { setAlamatCrew } from "../../services/CVStorage";
import { collectDataAlamat } from "../../redux/modules/collect.slice";
import { toast } from "react-hot-toast";

const FormAlamat = ({ domisili, ktp, isSubmitting, isPublic, next, prev }) => {
  const initialState = {
    alamat: [
      {
        tipe_alamat: "ktp",
        alamat: ktp?.alamat ?? "",
        no_rumah: ktp?.no_rumah ?? "",
        kecamatan: "",
        kabupaten: "",
        kelurahan: "",
        provinsi:  "",
        kode_pos: ktp?.kode_pos ?? "",
      },
      {
        tipe_alamat: "domisili",
        alamat: domisili?.alamat ?? "",
        no_rumah: domisili?.no_rumah ?? "",
        kecamatan: "",
        kabupaten: "",
        kelurahan: "",
        provinsi: "",
        kode_pos: domisili?.kode_pos ?? "",
      },
    ],
  };

  const validationAlamat = Yup.object().shape({
    alamat: Yup.array().of(
			Yup.object().shape({
				alamat: Yup.mixed().required("Form ini harus terisi."),
				no_rumah: Yup.mixed().required("Form ini harus terisi."),
				kecamatan: Yup.mixed().required("Form ini harus terisi."),
				kabupaten: Yup.mixed().required("Form ini harus terisi."),
				kelurahan: Yup.mixed().required("Form ini harus terisi."),
				provinsi: Yup.mixed().required("Form ini harus terisi."),
				kode_pos: Yup.mixed().required("Form ini harus terisi."),
			})
		),
  });

  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);

  const [optionProvinsi, setOptionProvinsi] = useState([]);
  const [optionKabupaten, setOptionKabupaten] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionKelurahan, setOptionKelurahan] = useState([]);

  const [optionProvinsi2, setOptionProvinsi2] = useState([]);
  const [optionKabupaten2, setOptionKabupaten2] = useState([]);
  const [optionKecamatan2, setOptionKecamatan2] = useState([]);
  const [optionKelurahan2, setOptionKelurahan2] = useState([]);

  const [loading_alamat, setLoadingAlamat] = useState(false)

  const [loading, setLoading] = useState({
    provinsi: false,
    kabupaten: false,
    kecataman: false,
    kelurahan: false,
  });

  useEffect(() => {
    const fetchProvinsi = async () => {
      let res = null;
      if (isPublic) {
        res = await dispatch(getProvinsiPublic());
      } else {
        res = await dispatch(getProvinsi());
      }

      if (res.type === "get-provinsi/fulfilled") {
        let tempArr = [];

        res.payload.map((val) => {
          tempArr.push({
            value: val.id,
            label: val.name,
          });
        });
        setOptionProvinsi(tempArr);
        setOptionProvinsi2(tempArr);
      }
    };

    fetchProvinsi();
  }, []);

  const onChangeProvinsi = async (val, setFieldValue, index) => {
    setLoading({ ...loading, provinsi: true });
    if (index === 0) {
      setFieldValue(`alamat[${index}].kabupaten`, null);
      setFieldValue(`alamat[${index}].kecamatan`, null);
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKabupaten([]);
      setOptionKecamatan([]);
      setOptionKelurahan([]);
    } else {
      setFieldValue(`alamat[${index}].kabupaten`, null);
      setFieldValue(`alamat[${index}].kecamatan`, null);
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKabupaten2([]);
      setOptionKecamatan2([]);
      setOptionKelurahan2([]);
    }

    setFieldValue(`alamat[${index}].provinsi`, val.label);

    let res = null;
    if (isPublic) {
      res = await dispatch(getKabupatenPublic({ id: val.value }));
    } else {
      res = await dispatch(getKabupaten({ id: val.value }));
    }

    if (res.type === "get-kabupaten/fulfilled") {
      const tempArr = Object.entries(res.payload).map(([value, label]) => ({
        value,
        label,
      }));

      if (index === 0) {
        setOptionKabupaten(tempArr);
      } else {
        setOptionKabupaten2(tempArr);
      }

      setLoading({ ...loading, provinsi: false });
    }
    setLoading({ ...loading, provinsi: false });
  };

  const onChangeKabupaten = async (val, setFieldValue, index) => {
    setLoading({ ...loading, kabupaten: true });
    setFieldValue(`alamat[${index}].kabupaten`, val.label);

    if (index === 0) {
      setFieldValue(`alamat[${index}].kecamatan`, null);
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKecamatan([]);
      setOptionKelurahan([]);
    } else {
      setFieldValue(`alamat[${index}].kecamatan`, null);
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKecamatan2([]);
      setOptionKelurahan2([]);
    }

    let res = null;
    if (isPublic) {
      res = await dispatch(getKecamatanPublic({ id: val.value }));
    } else {
      res = await dispatch(getKecamatan({ id: val.value }));
    }

    if (res.type === "get-kecamatan/fulfilled") {
      const tempArr = Object.entries(res.payload).map(([value, label]) => ({
        value,
        label,
      }));
      if (index === 0) {
        setOptionKecamatan(tempArr);
      } else {
        setOptionKecamatan2(tempArr);
      }
      setLoading({ ...loading, kabupaten: false });
    }
    setLoading({ ...loading, kabupaten: false });
  };

  const onChangeKecamatan = async (val, setFieldValue, index) => {
    setLoading({ ...loading, kabupaten: true });

    if (index === 0) {
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKelurahan([]);
    } else {
      setFieldValue(`alamat[${index}].kelurahan`, null);
      setOptionKelurahan2([]);
    }

    setFieldValue(`alamat[${index}].kelurahan`, null);

    setOptionKelurahan([]);

    setFieldValue(`alamat[${index}].kecamatan`, val.label);

    let res = null;

    if (isPublic) {
      res = await dispatch(getKelurahanPublic({ id: val.value }));
    } else {
      res = await dispatch(getKelurahan({ id: val.value }));
    }

    if (res.type === "get-kelurahan/fulfilled") {
      const tempArr = Object.entries(res.payload).map(([value, label]) => ({
        value,
        label,
      }));

      if (index === 0) {
        setOptionKelurahan(tempArr);
      } else {
        setOptionKelurahan2(tempArr);
      }

      setLoading({ ...loading, kabupaten: false });
    }
    setLoading({ ...loading, kabupaten: false });
  };

  const onChangeKelurahan = async (val, setFieldValue, index) => {
    setFieldValue(`alamat[${index}].kelurahan`, val.label);
  };

  const onChangeCheckbox = (checked, values, setFieldValue) => {
    setEnabled(checked);

    if (checked) {
      setFieldValue(`alamat[${1}].alamat`, values?.alamat?.[0]?.alamat);
      setFieldValue(`alamat[${1}].no_rumah`, values?.alamat?.[0]?.no_rumah);
      setFieldValue(`alamat[${1}].provinsi`, values?.alamat?.[0]?.provinsi);
      setFieldValue(`alamat[${1}].kecamatan`, values?.alamat?.[0]?.kecamatan);
      setFieldValue(`alamat[${1}].kabupaten`, values?.alamat?.[0]?.kabupaten);
      setFieldValue(`alamat[${1}].kelurahan`, values?.alamat?.[0]?.kelurahan);
      setFieldValue(`alamat[${1}].kode_pos`, values?.alamat?.[0]?.kode_pos);
    } else {
      setFieldValue(`alamat[${1}].alamat`, "");
      setFieldValue(`alamat[${1}].no_rumah`, "");
      setFieldValue(`alamat[${1}].provinsi`, "");
      setFieldValue(`alamat[${1}].kecamatan`, "");
      setFieldValue(`alamat[${1}].kabupaten`, "");
      setFieldValue(`alamat[${1}].kelurahan`, "");
      setFieldValue(`alamat[${1}].kode_pos`, "");
    }
  };

  const onSubmit = async (values) => {
    if (isPublic) {
      setLoadingAlamat(true)
      setAlamatCrew(values);

      let formData = new FormData()
      values?.alamat?.forEach((item, index) => {
        for (const property in item) {
          formData.append(`alamat[${index}][${property}]`, item[property]);
        }
      });
      let user_id = localStorage.getItem('user_id')

      const res = await dispatch(collectDataAlamat({id: Number(user_id), data:formData}))

      if(res.payload.data.status === false || res.payload.status === 400){
        setLoadingAlamat(false)
        toast.error(res.payload.data.data);
        return 
      }
      toast.success('Simpan data alamat berhasil.');
      setLoadingAlamat(false)

      return next();
    }

    values.alamat.map(val => {
      if(val.tipe_alamat === "ktp"){
        val.kabupaten = val.kabupaten ? val.kabupaten : ktp.kabupaten
        val.provinsi = val.provinsi ? val.provinsi : ktp.provinsi
        val.kecamatan = val.kecamatan ? val.kecamatan : ktp.kecamatan
        val.kelurahan = val.kelurahan ? val.kelurahan : ktp.kelurahan
        return val
      }else if(val.tipe_alamat === "domisili"){
        val.kabupaten = val.kabupaten ? val.kabupaten : domisili.kabupaten
        val.provinsi = val.provinsi ? val.provinsi : domisili.provinsi
        val.kecamatan = val.kecamatan ? val.kecamatan : domisili.kecamatan
        val.kelurahan = val.kelurahan ? val.kelurahan : domisili.kelurahan
        return val
      }
    })

    const res = await dispatch(setAlamat(values));

    if (res.payload.status === true) {

      await dispatch(getProfileUser());
    }
  };
  return (
    <>
      <Formik initialValues={initialState} onSubmit={onSubmit} >
        {({
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="text-sm">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
                  <h1 className="text-xl text-white font-bold ">
                    Alamat KTP/Domisili
                  </h1>
                </div>
              </div>
              <div className="col-span-1">
                <div className="mb-3">
                  <label>Tipe Alamat</label>
                  <Field
                    className="input-text bg-gray-400"
                    name={`alamat[${0}].tipe_alamat`}
                    value={values.alamat[0].tipe_alamat}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${0}].tipe_alamat`, e.target.value);
                    }}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label>Alamat Lengkap</label>
                  <Field
                    className="input-text"
                    name={`alamat[${0}].alamat`}
                    value={values.alamat[0].alamat}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${0}].alamat`, e.target.value);
                    }}
                    placeholder="Alamat lengkap..."
                  />
                  <ErrorMessage
										name={`alamat[${0}].alamat`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
                <div className="mb-3">
                  <label>No.Rumah</label>
                  <Field
                    type="number"
                    className="input-text"
                    name={`alamat[${0}].no_rumah`}
                    value={values.alamat[0].no_rumah}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${0}].no_rumah`, e.target.value);
                    }}
                    placeholder="No rumah..."
                  />
                  <ErrorMessage
										name={`alamat[${0}].no_rumah`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Provinsi</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionProvinsi}
                    onChange={(e) => onChangeProvinsi(e, setFieldValue, 0)}
                    onBlur={handleBlur}
                    name={`alamat[${0}].provinsi`}
                    value={optionProvinsi.find(
                      (option) => option.label === values.alamat[0].provinsi
                    )}
                  />
                  <span className="text-xs text-slate-500">
                    Provinsi :{" "}
                    {values?.alamat?.[0]?.provinsi
                      ? values?.alamat?.[0]?.provinsi
                      : ktp?.provinsi}
                  </span>
                  <ErrorMessage
										name={`alamat[${0}].provinsi`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
                <div className="mb-3">
                  <label>Kota / Kabupaten</label>
                  <Select
                    className={`react-select`}
                    // isDisabled={optionProvinsi ? false : true}
                    classNamePrefix="react-select"
                    options={optionKabupaten}
                    onChange={(e) => onChangeKabupaten(e, setFieldValue, 0)}
                    onBlur={handleBlur}
                    name={`alamat[${0}].kabupaten`}
                    defaultValue={values.alamat[0].kabupaten}
                    value={optionKabupaten.find(
                      (option) => option.label === values.alamat[0].kabupaten
                    )}
                    isDisabled={values.alamat[0].provinsi ? false : true}
                  />
                  <span className="text-xs text-slate-500">
                    Kabupaten / Kota :{" "}
                    {values?.alamat?.[0]?.kabupaten
                      ? values?.alamat?.[0]?.kabupaten
                      : ktp?.kabupaten}
                  </span>
                  <ErrorMessage
										name={`alamat[${0}].kabupaten`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Kecamatan</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionKecamatan}
                    onChange={(e) => onChangeKecamatan(e, setFieldValue, 0)}
                    onBlur={handleBlur}
                    name={`alamat[${0}].kecamatan`}
                    value={optionKecamatan.find(
                      (option) => option.label === values.alamat[0].kecamatan
                    )}
                    isDisabled={values.alamat[0].kabupaten ? false : true}
                  />
                  <span className="text-xs text-slate-500">
                    Kecamatan :{" "}
                    {values?.alamat?.[0]?.kecamatan
                      ? values?.alamat?.[0]?.kecamatan
                      : ktp?.kecamatan}
                  </span>
                  <ErrorMessage
										name={`alamat[${0}].kecamatan`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
                <div className="mb-3">
                  <label>Kelurahan</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionKelurahan}
                    onChange={(e) => onChangeKelurahan(e, setFieldValue, 0)}
                    onBlur={handleBlur}
                    name={`alamat[${0}].kelurahan`}
                    value={optionKelurahan.find(
                      (option) => option.label === values.alamat[0].kelurahan
                    )}
                    isDisabled={values.alamat[0].kecamatan ? false : true}
                  />
                  <span className="text-xs text-slate-500">
                    Kelurahan :{" "}
                    {values?.alamat?.[0]?.kelurahan
                      ? values?.alamat?.[0]?.kelurahan
                      : ktp?.kelurahan}
                  </span>
                  <ErrorMessage
										name={`alamat[${0}].kelurahan`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Kode Pos</label>
                  <Field
                    className="input-text"
                    name={`alamat[${0}].kode_pos`}
                    value={values.alamat[0].kode_pos}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${0}].kode_pos`, e.target.value);
                    }}
                    placeholder="Kodepos..."
                  />
                  <ErrorMessage
										name={`alamat[${0}].kode_pos`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
              </div>

              <div className="col-span-1">
                <div className="mb-3">
                  <label>Tipe Alamat</label>
                  <Field
                    className="input-text bg-gray-400"
                    name={`alamat[${1}].tipe_alamat`}
                    value={values.alamat[1].tipe_alamat}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${1}].tipe_alamat`, e.target.value);
                    }}
                    disabled={true}
                  />
                </div>

                <div className="mb-3">
                  <label>Alamat Lengkap</label>
                  <Field
                    className={`input-text ${enabled && "bg-gray-300"}`}
                    disabled={enabled ? true : false}
                    name={`alamat[${1}].alamat`}
                    value={values.alamat[1].alamat}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${1}].alamat`, e.target.value);
                    }}
                    placeholder="Alamat lengkap..."
                  />
                  <ErrorMessage
										name={`alamat[${1}].alamat`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>No.Rumah</label>
                  <Field
                    className={`input-text ${enabled && "bg-gray-300"}`}
                    disabled={enabled ? true : false}
                    name={`alamat[${1}].no_rumah`}
                    value={values.alamat[1].no_rumah}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${1}].no_rumah`, e.target.value);
                    }}
                    placeholder="No rumah..."
                  />
                  <ErrorMessage
										name={`alamat[${1}].no_rumah`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Provinsi</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionProvinsi2}
                    onChange={(e) => onChangeProvinsi(e, setFieldValue, 1)}
                    onBlur={handleBlur}
                    name={`alamat[${1}].provinsi`}
                    value={optionProvinsi.find(
                      (option) => option.label === values.alamat[1].provinsi
                    )}
                    isDisabled={enabled ? true : false}
                  />
                  <span className="text-xs text-slate-500">
                    Provinsi :{" "}
                    {values?.alamat?.[1]?.provinsi
                      ? values?.alamat?.[1]?.provinsi
                      : domisili?.provinsi}
                  </span>
                  <ErrorMessage
										name={`alamat[${1}].provinsi`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
                <div className="mb-3">
                  <label>Kota / Kabupaten</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionKabupaten2}
                    onChange={(e) => onChangeKabupaten(e, setFieldValue, 1)}
                    onBlur={handleBlur}
                    name={`alamat[${1}].kabupaten`}
                    value={optionKabupaten2.find(
                      (option) => option.label === values.alamat[1].kabupaten
                    )}
                    isDisabled={
                      !enabled && values.alamat[1].provinsi ? false : true
                    }
                  />
                  <span className="text-xs text-slate-500">
                    Kabupaten / kota :{" "}
                    {values?.alamat?.[1]?.kabupaten
                      ? values?.alamat?.[1]?.kabupaten
                      : domisili?.kabupaten}
                  </span>
                  <ErrorMessage
										name={`alamat[${1}].kabupaten`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Kecamatan</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionKecamatan2}
                    onChange={(e) => onChangeKecamatan(e, setFieldValue, 1)}
                    onBlur={handleBlur}
                    name={`alamat[${1}].kecamatan`}
                    value={optionKecamatan2.find(
                      (option) => option.label === values.alamat[1].kecamatan
                    )}
                    isDisabled={
                      !enabled && values.alamat[1].kabupaten ? false : true
                    }
                  />
                  <span className="text-xs text-slate-500">
                    Kecamatan :{" "}
                    {values?.alamat?.[1]?.kecamatan
                      ? values?.alamat?.[1]?.kecamatan
                      : domisili?.kecamatan}
                  </span>
                  <ErrorMessage
										name={`alamat[${1}].kecamatan`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
                <div className="mb-3">
                  <label>Kelurahan</label>
                  <Select
                    className={`react-select`}
                    classNamePrefix="react-select"
                    options={optionKelurahan2}
                    onChange={(e) => onChangeKelurahan(e, setFieldValue, 1)}
                    onBlur={handleBlur}
                    name={`alamat[${1}].kelurahan`}
                    value={optionKelurahan2.find(
                      (option) => option.label === values.alamat[1].kelurahan
                    )}
                    isDisabled={
                      !enabled && values.alamat[1].kecamatan ? false : true
                    }
                  />
                  <span className="text-xs text-slate-500">
                    Kelurahan :{" "}
                    {values?.alamat?.[1]?.kelurahan
                      ? values?.alamat?.[1]?.kelurahan
                      : domisili?.kelurahan}
                  </span>
                  <ErrorMessage
										name={`alamat[${1}].kelurahan`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>

                <div className="mb-3">
                  <label>Kode Pos</label>
                  <Field
                    className={`input-text ${enabled && "bg-gray-300"}`}
                    disabled={enabled ? true : false}
                    name={`alamat[${1}].kode_pos`}
                    value={values.alamat[1].kode_pos}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(`alamat[${1}].kode_pos`, e.target.value);
                    }}
                    placeholder="Kodepos..."
                  />
                  <ErrorMessage
										name={`alamat[${1}].kode_pos`}
										component="div"
										style={{ color: "red" }}
									/>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-end space-x-2">
              <input
                type="checkbox"
                className=""
                checked={enabled}
                onChange={(e) =>
                  onChangeCheckbox(e.target.checked, values, setFieldValue)
                }
              />
              <span className="text-xs text-slate-500">
                Alamat domisili sama dengan KTP
              </span>
            </div>

            <div className="text-center lg:text-left flex mt-4">
              {isPublic && (
                <button
                  type="button"
                  className="button-submit block mr-2"
                  onClick={() => {
                    prev();
                  }}
                >
                  {`<`} Prev
                </button>
              )}

              <button type="submit" className="button-submit block">
                {isSubmitting || loading_alamat ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    size="xl"
                    className="text-white animate-spin"
                  />
                ) : isPublic ? (
                  "Simpan >"
                ) : (
                  "Save Data"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormAlamat;

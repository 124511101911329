import CareerOpportunity from "../views/app/career-opporturnity";
import MyApplication from "../views/app/my-application";
import Profile from "../views/app/profile";
import EditProfile from "../views/app/profile/EditProfile";

const appRoutes = [
  {
    path: "edit-profile",
    element: <EditProfile />,
  },
  {
    path: "career-opportunities",
    element: <CareerOpportunity />,
  },
  {
    path: "my-application",
    element: <MyApplication />,
  },
];

export default appRoutes;

import { toast } from "react-hot-toast";

export const clearCache = () => {
    var all_cookies = document.cookie.split(";");
    for (var i = 0; i < all_cookies.length; i++) {
        var single_cookie = all_cookies[i];
        var cookie_index = single_cookie.indexOf("=");
        var cookie_name = cookie_index > -1 ? all_cookies.substr(0, cookie_index) : single_cookie;
        document.cookie = cookie_name + "=;expires=" + new Date().toUTCString();
    }

    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }

    window.localStorage.clear()

    toast.success("Cache berhasil di clear.")

    setTimeout(() => {
        window.location.reload()
    },1000)    
}
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setDataDiri } from "../../redux/modules/profile.slice";
import CountAge from "../../helper/CountAge";
import { getProfileUser } from "../../redux/auth.slice";
import { setBiodata } from "../../services/CVStorage";
import { toast } from "react-hot-toast";
import { notifyError } from "../../components/notification/Toast";
import { collectDataDiri } from "../../redux/modules/collect.slice";

const optionsWearpack = [
  { value: 'XS', text: 'XS' },
  { value: 'S', text: 'S' },
  { value: 'M', text: 'M' },
  { value: 'L', text: 'L' },
  { value: 'XL', text: 'XL' },
  { value: '2XL', text: '2XL' },
  { value: '3XL', text: '3XL' },
  { value: '4XL', text: '4XL' },
  { value: '5XL', text: '5XL' },
]

const FormDataDiri = ({
  data,
  isSubmitting,
  email,
  photo,
  listPosition,
  listBank,
  listVehicle,
  next,
  prev,
  isPublic,
  setReadyOnSave,
  listGolonganDarah,
  cv
}) => {
  const initialState = {
    full_name: data?.full_name ?? "",
    npwp_number: data?.npwp_number ?? "",
    bpjs_tk_number: data?.bpjs_tk_number ?? "",
    bpjs_kes_number: data?.bpjs_kes_number ?? "",
    sign_on: data?.sign_on ?? null,
    join_on_board: data?.join_on_board ?? null,
    nick_name: data?.nick_name ?? "",
    seaferer_code: data?.seaferer_code ?? "",
    hp_number: data?.hp_number ?? "",
    golongan_darah_id: data?.golongan_darah_id ?? "",
    gender: data?.gender ?? null,
    seaman_book_number: data?.seaman_book_number ?? null,
    passport_number: data?.passport_number ?? null,
    place_of_birth: data?.place_of_birth ?? "",
    date_of_birth: data?.date_of_birth ? data?.date_of_birth : null,
    port_id: data?.port_id ?? "",
    religion: data?.religion?.id
      ? Number(data?.religion?.id)
      : data?.religion
      ? Number(data?.religion)
      : null,
    marital_status: data?.marital_status?.id
      ? Number(data?.marital_status?.id)
      : data?.marital_status
      ? Number(data?.marital_status)
      : null,
    email: email ?? "",
    nik_ktp: data?.nik_ktp ?? "",
    no_kk: data?.no_kk ?? "",
    citizenship: data?.citizenship ?? "",
    last_position: data?.position?.id
      ? Number(data?.position?.id)
      : data?.last_position
      ? Number(data?.last_position)
      : null,
    rekening_name: data?.rekening_name ?? "",
    rekening_number: data?.rekening_number ?? "",
    bank_id: data?.bank_id ?? null,

    height: data?.height ?? null,
    weight: data?.weight ?? null,
    wearpack: data?.wearpack ?? '',
    size_of_shoes: data?.size_of_shoes ?? null,
    helmet: data?.helmet ?? '',
  };

  const DataDiriSchema = Yup.object().shape({
    full_name: Yup.mixed().required("Form ini harus terisi."),
    email: Yup.mixed().required("Form ini harus terisi."),
    nik_ktp: Yup.string()
    .min(16, "No.KTP harus 16 karakter.")
    .max(16, "No.KTP harus 16 karakter.")
    .required("Form ini harus terisi."),

    no_kk: Yup.string()
      .min(16, "No.KK harus 16 karakter.")
      .max(16, "No.KK harus 16 karakter.")
      .required("Form ini harus terisi."),
    // bpjs_tk_number: Yup.mixed().required("Form ini harus terisi."),
    // bpjs_kes_number: Yup.mixed().required("Form ini harus terisi."),
    seaman_book_number: Yup.mixed().required("Form ini harus terisi."),

    npwp_number: Yup.mixed().required("Form ini harus terisi."),

    bank_id: Yup.mixed().required("Form ini harus terisi."),
    rekening_name: Yup.mixed().required("Form ini harus terisi."),
    rekening_number: Yup.mixed().required("Form ini harus terisi."),

    seaferer_code: Yup.mixed().required("Form ini harus terisi."),
    hp_number: Yup.mixed().required("Form ini harus terisi."),
    gender: Yup.mixed().required("Form ini harus terisi."),

    place_of_birth: Yup.mixed().required("Form ini harus terisi."),
    date_of_birth: Yup.mixed().required("Form ini harus terisi."),

    religion: Yup.mixed().required("Form ini harus terisi."),
    marital_status: Yup.mixed().required("Form ini harus terisi."),
    citizenship: Yup.mixed().required("Form ini harus terisi."),
    last_position: Yup.mixed().required("Form ini harus terisi."),
    golongan_darah_id: Yup.mixed().required("Form ini harus terisi."),
  });

  const { userData } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false)

  const [vehicle, setVehicle] = useState(data?.vehicle);

  const dispatch = useDispatch();

  const onSubmit = async (values, {setSubmitting}) => {
    if (isPublic) {
      if(!values.sign_on){
        return toast.error('Sign On Harus Terisi.');
      } else if (!values.join_on_board){
        return toast.error('Join on Board Harus Terisi.');
      } else if (!vehicle){
        return toast.error('Kapal Harus Terisi.');
      }
      setLoading(true)

      let newVal = {
        ...values,
        vehicle: vehicle,
      };
      await setBiodata(newVal);

      let formData = new FormData();

		formData.append("seaferer_code", newVal?.seaferer_code);
		formData.append("nik_ktp", newVal?.nik_ktp.toString());
		
		formData.append("full_name", newVal?.full_name);
		formData.append("nick_name", newVal?.nick_name);
		formData.append("email", newVal?.email);
		formData.append("hp_number", newVal?.hp_number);
		formData.append("gender", newVal?.gender);
		formData.append("seaman_book_number", newVal?.seaman_book_number);
		formData.append("passport_number", newVal?.passport_number);
		formData.append("place_of_birth", newVal?.place_of_birth);
		formData.append("date_of_birth", newVal?.date_of_birth);
		formData.append("religion", newVal?.religion);
		formData.append("marital_status", newVal?.marital_status);
		formData.append("citizenship", newVal?.citizenship);
		formData.append("npwp_number", newVal?.npwp_number);
		formData.append("bpjs_kes_number", newVal?.bpjs_kes_number);
		formData.append("bpjs_tk_number", newVal?.bpjs_tk_number);
		formData.append("rekening_number", newVal?.rekening_number);
		formData.append("rekening_name", newVal?.rekening_name);
		formData.append("bank_id", newVal?.bank_id);
		formData.append("last_position", Number(newVal?.last_position));
		formData.append("vehicle_id", Number(newVal?.vehicle?.value));
		formData.append("port_id", newVal?.port_id);
	  formData.append("golongan_darah_id", newVal?.golongan_darah_id);

		formData.append("height", newVal?.height?.toString());
		formData.append("weight", newVal?.weight?.toString());
		formData.append("wearpack", newVal?.wearpack);
		formData.append("size_of_shoes", newVal?.size_of_shoes);
		formData.append("helmet", newVal?.helmet);

		formData.append("no_kk", newVal?.no_kk.toString());
		formData.append("sign_on", newVal?.sign_on);
		formData.append("join_on_board", newVal?.join_on_board);

		if (photo) {
			formData.append("photo", photo);
		}

		if (cv) {
			formData.append("cv_file", cv);
		}

      const res = await dispatch(collectDataDiri(formData))

      if(res.payload.data.status === false || res.payload.status === 400){
        setLoading(false)
        toast.error(res.payload.data.data);
        return 
      }
      localStorage.setItem('user_id', res.payload.data[0].id)
      toast.success('Simpan data diri berhasil.');
      setLoading(false)
      return next();
    }

    if(!cv && !userData?.personal_data?.cv_file){
      notifyError("Silahkan Upload CV Terlebih dahulu");
      notifyError("Upload CV Required");
      return
    }

    const formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }

    if (cv) {
      formData.append("cv_file", cv);
    }

    formData.append("last_position", values.last_position);
    formData.append("full_name", values.full_name);
    formData.append("nick_name", values.nick_name);
    formData.append("seaferer_code", values.seaferer_code);
    formData.append("hp_number", values.hp_number);
    formData.append("gender", values.gender);
    formData.append("seaman_book_number", values.seaman_book_number);
    formData.append("passport_number", values.passport_number);
    formData.append("place_of_birth", values.place_of_birth);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("golongan_darah_id", values.golongan_darah_id);

    formData.append("religion", values.religion);
    formData.append("marital_status", values.marital_status);
    formData.append("email", values.email);
    formData.append("nik_ktp", values.nik_ktp);
    formData.append("no_kk", values.no_kk);
    formData.append("citizenship", values.citizenship);

    formData.append("bank_id", values.bank_id);
    formData.append("rekening_number", values.rekening_number);
    formData.append("rekening_name", values.rekening_name);
    formData.append("npwp_number", values.npwp_number);
    formData.append("bpjs_tk_number", values.bpjs_tk_number);
    formData.append("bpjs_kes_number", values.bpjs_kes_number);

    formData.append("height", values.height);
    formData.append("weight", values.weight);
    formData.append("wearpack", values.wearpack);
    formData.append("size_of_shoes", values.size_of_shoes);
    formData.append("helmet", values.helmet);

    const res = await dispatch(setDataDiri(formData));

    if (res?.payload?.status === true) {
      await dispatch(getProfileUser());
    }
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validationSchema={DataDiriSchema}
      >
        {({
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="text-sm">
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-2 ">
                <div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md ">
                  <h1 className="text-xl text-white font-bold ">Biodata</h1>
                </div>
              </div>
              <div className="col-span-1">
                <div className="mb-3">
                  <label>Email</label>
                  <Field
                    className={`input-text `}
                    name="email"
                    value={values?.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Email..."
                    disabled={!isPublic ? true : false}
                  />
                   {errors.email && touched.email ? (
                    <div className="block text-red-500">
                      *{errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Nama Lengkap</label>
                  <Field
                    className="input-text"
                    name="full_name"
                    value={values?.full_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Nama lengkap..."
                  />
                  {errors.full_name && touched.full_name ? (
                    <div className="block text-red-500">
                      *{errors.full_name}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Nama Panggilan</label>
                  <Field
                    className="input-text "
                    name="nick_name"
                    value={values?.nick_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Nama panggilan..."
                  />
                </div>
                <div className="mb-3">
                  <label>No Handphone</label>
                  <Field
                    className="input-text"
                    name="hp_number"
                    value={values?.hp_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="No Hp..."
                  />
                  {errors.hp_number && touched.hp_number ? (
                    <div className="block text-red-500">
                      *{errors.hp_number}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Jenis Kelamin</label>
                  <select
                    name="gender"
                    className="input-text"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Options...</option>
                    <option value="Male">Laki - laki</option>
                    <option value="Female">Perempuan</option>
                  </select>
                  {errors.gender && touched.gender ? (
                    <div className="block text-red-500">*{errors.gender}</div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Tempat Lahir</label>
                  <Field
                    className="input-text"
                    name="place_of_birth"
                    value={values?.place_of_birth}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Tempat lahir..."
                  />
                  {errors.place_of_birth && touched.place_of_birth ? (
                    <div className="block text-red-500">
                      *{errors.place_of_birth}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Tanggal Lahir</label>
                  <Field
                    type="date"
                    className="input-text"
                    name="date_of_birth"
                    value={values?.date_of_birth}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Tanggal lahir..."
                  />
                  {errors.date_of_birth && touched.date_of_birth ? (
                    <div className="block text-red-500">
                      *{errors.date_of_birth}
                    </div>
                  ) : null}
                </div>
               
                <div className="mb-3">
                  <label>Agama</label>
                  <select
                    name="religion"
                    className="input-text"
                    value={values.religion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Status...</option>
                    <option value={1}>Islam</option>
                    <option value={2}>Protestan</option>
                    <option value={3}>Katolik</option>
                    <option value={4}>Hindu</option>
                    <option value={5}>Buddha</option>
                    <option value={6}>Khonghucu</option>
                  </select>

                  {errors.religion && touched.religion ? (
                    <div className="block text-red-500">*{errors.religion}</div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Status Pernikahan</label>
                  <select
                    name="marital_status"
                    className="input-text"
                    value={values.marital_status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Status...</option>
                    <option value={1}>Single</option>
                    <option value={2}>Marriage</option>
                  </select>

                  {errors.marital_status && touched.marital_status ? (
                    <div className="block text-red-500">
                      *{errors.marital_status}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Kewarganegaraan</label>
                  <select
                    name="citizenship"
                    className="input-text"
                    value={values.citizenship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Select Status...</option>
                    <option value="WNI">Indonesia (WNI)</option>
                    <option value="WNA">Asing (WNA)</option>
                  </select>

                  {errors.citizenship && touched.citizenship ? (
                    <div className="block text-red-500">
                      *{errors.citizenship}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Golongan Darah</label>
                  <select
                    name="golongan_darah_id"
                    className="input-text"
                    value={values.golongan_darah_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Options...</option>
                    {listGolonganDarah?.map((val) => (
                      <option value={val.value}>{val.text}</option>
                    ))}
                  </select>
                  {errors.golongan_darah_id && touched.golongan_darah_id ? (
                    <div className="block text-red-500">*{errors.golongan_darah_id}</div>
                  ) : null}
                </div>
                   <div className="mb-3">
                  <label>Wearpack</label>
                  <select
                    name="wearpack"
                    className="input-text"
                    value={values.wearpack}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=''>Select Options...</option>
                   {optionsWearpack?.map((val, i) => (
                    <option key={i} value={val.value}>{val.text}</option>
                   ))}
                  </select>
                  {errors.wearpack && touched.wearpack ? (
                    <div className="block text-red-500">
                      *{errors.wearpack}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Size Sepatu</label>
                  <Field
                    className="input-text"
                    name="size_of_shoes"
                    value={values?.size_of_shoes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Size sepatu..."
                  />
                  {errors.size_of_shoes && touched.size_of_shoes ? (
                    <div className="block text-red-500">
                      *{errors.size_of_shoes}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Helmet</label>
                  <Field
                    className="input-text"
                    name="helmet"
                    value={values?.helmet}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Helmet..."
                  />
                  {errors.helmet && touched.helmet ? (
                    <div className="block text-red-500">
                      *{errors.helmet}
                    </div>
                  ) : null}
                </div>
                {isPublic && (
                  <div className="mb-3">
                    <label>Kapal</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix="react-select"
                      options={listVehicle}
                      onChange={setVehicle}
                      onBlur={handleBlur}
                      name="vehicle"
                      value={vehicle}
                    />
                  </div>
                )}{" "}
                {isPublic && (
                  <div className="mb-3">
                    <label>Pelabuhan</label>
                    <Field
                      className="input-text"
                      name="port_id"
                      value={values?.port_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Pelabuhan..."
                    />
                    {errors.port_id && touched.port_id ? (
                      <div className="block text-red-500">
                        *{errors.port_id}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="col-span-1">
                <div className="mb-3">
                  <label>No KTP</label>
                  <Field
         
                    className={`input-text`}
                    name="nik_ktp"
                    value={values?.nik_ktp}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="xxxx xxxx xxxx xxxx"
                    disabled={!data?.nik_ktp || isPublic ? false : true}
                  />
                    {errors.nik_ktp && touched.nik_ktp ? (
                    <div className="block text-red-500">
                      *{errors.nik_ktp}
                    </div>
                  ) : null}
                </div> 
                
                <div className="mb-3">
                  <label>No Kartu Keluarga (KK)</label>
                  <Field
           
                    className={`input-text`}
                    name="no_kk"
                    value={values?.no_kk}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="xxxx xxxx xxxx xxxx"
                    disabled={!data?.no_kk || isPublic ? false : true}
                  />
                    {errors.no_kk && touched.no_kk ? (
                    <div className="block text-red-500">
                      *{errors.no_kk}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label>Seaferer Code</label>
                  <Field
                    className="input-text"
                    name="seaferer_code"
                    value={values?.seaferer_code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Seaferer code..."
                  />
                  {errors.seaferer_code && touched.seaferer_code ? (
                    <div className="block text-red-500">
                      *{errors.seaferer_code}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Seaman Book Number</label>
                  <Field
                    className="input-text"
                    name="seaman_book_number"
                    value={values?.seaman_book_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Seaman book..."
                  />
                  {errors.seaman_book_number && touched.seaman_book_number ? (
                    <div className="block text-red-500">
                      *{errors.seaman_book_number}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Passport Number</label>
                  <Field
                    className="input-text"
                    name="passport_number"
                    value={values?.passport_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Passport...."
                  />
                  {errors.passport_number && touched.passport_number ? (
                    <div className="block text-red-500">
                      *{errors.passport_number}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>No NPWP</label>
                  <Field
                    className="input-text "
                    name="npwp_number"
                    value={values?.npwp_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="No NPWP..."
                  />
                  {errors.npwp_number && touched.npwp_number ? (
                    <div className="block text-red-500">
                      *{errors.npwp_number}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Jabatan</label>
                  <select
                    name="last_position"
                    className="input-text"
                    value={values.last_position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Options...</option>
                    {listPosition.map((val) => (
                      <option value={val.value}>{val.text}</option>
                    ))}
                  </select>
                  {errors.last_position && touched.last_position ? (
                    <div className="block text-red-500">
                      *{errors.last_position}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>No BPJS Kesehatan</label>
                  <Field
                    className="input-text"
                    name="bpjs_kes_number"
                    value={values?.bpjs_kes_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="No BPJS Kesehatan...."
                  />
                  {errors.bpjs_kes_number && touched.bpjs_kes_number ? (
                    <div className="block text-red-500">
                      *{errors.bpjs_kes_number}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>No BPJS Ketenagakerjaan</label>
                  <Field
                    className="input-text"
                    name="bpjs_tk_number"
                    value={values?.bpjs_tk_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="No BPJS Ketenagakerjaan...."
                  />
                  {errors.bpjs_tk_number && touched.bpjs_tk_number ? (
                    <div className="block text-red-500">
                      *{errors.bpjs_tk_number}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label>Nama Pemilik Bank</label>
                  <Field
                    className="input-text"
                    name="rekening_name"
                    value={values?.rekening_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Pemilik bank..."
                  />
                  {errors.rekening_name && touched.rekening_name ? (
                    <div className="block text-red-500">
                      *{errors.rekening_name}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Bank</label>
                  <select
                    name="bank_id"
                    className="input-text"
                    value={values.bank_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={null}>Select Options...</option>
                    {listBank.map((val) => (
                      <option value={val.value}>{val.text}</option>
                    ))}
                  </select>
                  {errors.bank_id && touched.bank_id ? (
                    <div className="block text-red-500">*{errors.bank_id}</div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>No Rekening</label>
                  <Field
                    className="input-text"
                    name="rekening_number"
                    value={values?.rekening_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="No rekening..."
                  />
                  {errors.rekening_number && touched.rekening_number ? (
                    <div className="block text-red-500">
                      *{errors.rekening_number}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label>Tinggi Badan</label>
                  <Field
                  type="number"
                    className="input-text"
                    name="height"
                    value={values?.height}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Tinggi badan..."
                  />
                  {errors.height && touched.height ? (
                    <div className="block text-red-500">
                      *{errors.height}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Berat Badan</label>
                  <Field
                    type="number"
                    className="input-text"
                    name="weight"
                    value={values?.weight}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Berat badan..."
                  />
                  {errors.weight && touched.weight ? (
                    <div className="block text-red-500">
                      *{errors.weight}
                    </div>
                  ) : null}
                </div>

                {isPublic && <>
                  <div className="mb-3">
                  <label>Sign On</label>
                  <Field
                    type="date"
                    className="input-text"
                    name="sign_on"
                    value={values?.sign_on}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Sign On..."
                  />
                  {errors.sign_on && touched.sign_on ? (
                    <div className="block text-red-500">
                      *{errors.sign_on}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Join On Board</label>
                  <Field
                    type="date"
                    className="input-text"
                    name="join_on_board"
                    value={values?.join_on_board}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Join On Board..."
                  />
                  {errors.join_on_board && touched.join_on_board ? (
                    <div className="block text-red-500">
                      *{errors.join_on_board}
                    </div>
                  ) : null}
                </div>
                </>
                }
              </div>
            </div>

            <div className="text-center lg:text-left">
              <button type="submit" className="button-submit" disabled={isSubmitting || loading}>
                {isSubmitting || loading ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    size="xl"
                    className="text-white animate-spin"
                  />
                ) : isPublic ? (
                  "Simpan >"
                ) : (
                  "Save Data"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormDataDiri;

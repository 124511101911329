import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo-triton.png";
import userFemale from "../../assets/user/11.png";
import userMale from "../../assets/user/09.jpg";

import { useSelector } from "react-redux";

const Topnav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, userData } = useSelector((state) => state.user);

  return (
    <nav className="bg-white border-[#29B6AE] px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 shadow-lg z-[100] border-b-2">
      <div className="container flex flex-wrap max-w-7xl  items-center justify-between mx-auto">
        {/* LOGO */}
        <NavLink className="flex items-center" to="/">
          <img
            src={Logo}
            className="w-[10rem] h-[4.5rem]"
            alt="Transcol Logo"
          />
        </NavLink>
        <div
          className="flex items-center md:order-2  border rounded-md border-[#29B6AE]  px-4 py-2 hover:bg-[#29B6AE] transition-all duration-300"
          // onClick={() => navigate("/profile")}
        >
          <div className="flex items-center md:order-2 space-x-2">
            <div className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
              <img
                className="w-8 h-8 rounded-full"
                src={
                  userData?.photo ?? userData?.personal_data?.gender === "Male"
                    ? userMale
                    : userFemale
                }
                alt="user photo"
              />
            </div>
            <div className="">
              <span className="block text-sm text-gray-900 dark:text-white">
                {currentUser?.fullname}
              </span>
            </div>
          </div>
        </div>

        {/* NAVIGATION */}
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-2"
        >
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <NavLink
                to="/"
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/"
                    ? "text-[#29B6AE] border-b-[#29B6AE]"
                    : "text-slate-400 border-b-slate-400"
                } rounded md:bg-transparent md:p-0`}
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/my-application"
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/my-application"
                    ? "text-[#29B6AE] border-b-[#29B6AE]"
                    : "text-slate-400 border-b-slate-400"
                } rounded md:bg-transparent md:p-0`}
              >
                My Application
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/career-opportunities"
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/career-opportunities"
                    ? "text-[#29B6AE] border-b-[#29B6AE]"
                    : "text-slate-400 border-b-slate-400"
                } rounded md:bg-transparent md:p-0`}
              >
                Career Opportunities
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Topnav;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteDokumenPendukung,

	getMasterDocumentList,
	setDocumentChecklist,

} from "../../redux/modules/profile.slice";

import { SwalSuccess } from "../../components/notification/Swal";
import { updateSertifikasiSupportDokumen } from "../../redux/auth.slice";
import { notifyError } from "../../components/notification/Toast";
import PdfLink from "../../helper/PDFLink";
import Select from "react-select";
import { setDokumenPendukungCrew } from "../../services/CVStorage";
import Swal from "sweetalert2";
import getBinaryFromFile from "../../helper/convertBinary";
import { collectDataSupport } from "../../redux/modules/collect.slice";
import { toast } from "react-hot-toast";

const FormDocumentChecklist = ({
	isSubmitting,
	data,
	isPublic,
	prev,
	next,
	setReadyOnSave,
	readyOnSave,
}) => {
	const initialState = {
		support_document:
			data?.length > 0
				? data
				: [
						{
							document_list_id: null,
							file: null,
						},
				  ],
	};
	const dispatch = useDispatch();

	const [manualDocument, setManualDocument] = useState([]);
	const [loading, setLoading] = useState(false);

	const validationSupportDokumen = Yup.object().shape({
		support_document: Yup.array().of(
			Yup.object().shape({
				file: Yup.mixed().required("Form ini harus terisi."),
			})
		),
	});

	const handleFileSelect = async (event, index, setFieldValue) => {
		const file = event.target.files[0];
		var fileSizeInMB = file.size / (1024 * 1024);

		if(fileSizeInMB > 10){
			return notifyError('File tidak boleh lebih dari 10 MB')
		}
		setFieldValue(`support_document[${index}].file`, file);
	};

	const handleDokumenListChange = (e, index, setFieldValue) => {
		setFieldValue(`support_document[${index}].document_list_id`, e.value);

		// setManualDocument((prevItems) =>
		// 	prevItems.filter((item) => item.value !== Number(e.value))
		// );
	};

	useEffect(() => {
		const fetchDocumentList = async () => {
			let params = {
				type: "manual",
				is_travel: 0,
			};

			let newManual = [];
			const res = await dispatch(getMasterDocumentList(params));
			if (res.payload.status) {
				res.payload.data.data.map((val) => {
					newManual.push({
						label: val.name,
						value: val.id,
					});
				});

				// const filteredOptions = newManual.filter(obj =>
				// 	!data.some(targetObj =>
				// 	  obj.value === targetObj.document_list_id 
				// 	)
				//   );

				// console.log(filteredOptions, "is this working")

				setManualDocument(newManual);
			}
		};
		fetchDocumentList();
	}, []);

	const eventAddData = (values, setFieldValue) => {
		let newArr = [...values.support_document];
		newArr.push({
			document_list_id: null,
			file: null,
		});
		setFieldValue("support_document", newArr);
	};

	const onDelete = (values, index, setFieldValue) => {
		if(!values.support_document[index].id){
		  return  setFieldValue(
			"support_document",
			values.support_document.filter((_, i) => i !== index)
		)
		}
  
		Swal.fire({
		  title: 'Apakah yakin ingin menghapus data ini?',
		  text: "Data yang terhapus tidak akan kembali.",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Ya, Hapus!'
		}).then(async (result) => {
		  if (result.isConfirmed) {
  
			const res = await dispatch(deleteDokumenPendukung(values.support_document[index].id))
  
			if(res.payload.status === true){
				setFieldValue(
					"support_document",
					values.support_document.filter((_, i) => i !== index)
				  )
		
				  Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				  )
			}
		  }
		})
	}

	const onSubmit = async (values) => {
		if (isPublic) {
			await setDokumenPendukungCrew(values);
			setReadyOnSave({
				...readyOnSave,
				support_document: values.support_document,
			});

			setLoading(true)

			let formData = new FormData()

			values?.support_document?.forEach((item, index) => {
			  for (const property in item) {
				formData.append(`support_document[${index}][${property}]`, item[property]);
			  }
			});
	  
			let user_id = localStorage.getItem('user_id')
	  
			const res = await dispatch(collectDataSupport({id: Number(user_id), data:formData}))
	  
			if(res.payload.data.status === false || res.payload.status === 400){
			  setLoading(false)
			  toast.error(res.payload.data.data);
			  return 
			}
			toast.success('Simpan data support dokumen berhasil.');
			setLoading(false)
	  
			return next();
		}

		let newArr = []

		values.support_document.map(val => {
			if(typeof val.file !== 'string'){
				newArr.push(val)
			}
		})

		let err = null;
		const promises = newArr.map(async (val) => {
			const formData = new FormData();
			formData.append(`document_list_id`, val.document_list_id);
			formData.append(`file`, val.file);
			const res = await dispatch(setDocumentChecklist(formData));

			if (res?.payload?.data?.status === false) {
				err = true;
			}
		});

		await Promise.all(promises).then(async () => {
			if (err) {
				return notifyError("Gagal Update Dokumen");
			}
			SwalSuccess(
				"Update Data Dokumen berhasil.",
				"User berhasil melakukan update Dokumen Penting."
			);
			dispatch(updateSertifikasiSupportDokumen(values.support_document));
		});
	};

	return (
		<>
			<div className="flex mb-4">
				<div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
					<h1 className="text-xl text-white font-bold ">Dokumen Pendukung</h1>
				</div>
			</div>
			<Formik
				initialValues={initialState}
				onSubmit={onSubmit}
				// validationSchema={validationSupportDokumen}
			>
				{({
					errors,
					touched,
					values,
					setFieldTouched,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => (
					<Form className="text-sm">
						{values?.support_document?.map((val, index) => (
							<div
								className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
								key={index}
							>
								<div className="mb-3        col-span-6 sm:col-span-5">
									<label>Pilih Dokumen</label>
									<Select
										className={`react-select`}
										classNamePrefix="react-select"
										options={manualDocument}
										isSearchable={false}
										onChange={(e) =>
											handleDokumenListChange(e, index, setFieldValue)
										}
										onBlur={handleBlur}
										name={`support_document[${index}].document_list_id`}
										id={`support_document[${index}].document_list_id`}
										value={manualDocument.find(
											(option) =>
												option.value ===
												values?.support_document?.[index]?.document_list_id
										)}
									/>
									<ErrorMessage
										name={`support_document[${index}].negara`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="  col-span-6 ">
									<label>File</label>

									<input
										type="file"
										className="input-text  "
										name={`support_document[${index}].file`}
										id={`support_document[${index}].file`}
										onBlur={handleBlur}
										accept="image/*, application/pdf"
										onChange={(event) =>
											handleFileSelect(event, index, setFieldValue)
										}
										placeholder="File..."
									/>
									<div>
										{values?.support_document?.[index]?.file_url  ? (
											<PdfLink url={values?.support_document?.[index]?.file_url} />
										) : (
											<span className="text-red-500">{!isPublic && 'No file'}</span>
										)}
									</div>
									<span className="text-gray-400 text-xs">File Size tidak boleh melebihi <strong className="text-[#29B6AE]">10 MB</strong></span>
									
									<ErrorMessage
										name={`support_document[${index}].file`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								{index === 0 ? (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Tambah</label>
										<button
											type="button"
											className={`button-submit block mb-4 ${
												manualDocument.length < 1 && "bg-gray-500"
											}`}
											onClick={() => eventAddData(values, setFieldValue)}
											disabled={manualDocument.length < 1 ? true : false}
										>
											<FontAwesomeIcon
												icon="plus"
												size="lg"
												className="text-white "
											/>
										</button>
									</div>
								) : (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Hapus</label>
										<button
											type="button"
											className="button-submit block mb-4 bg-red-500"
											onClick={() =>
												onDelete(values, index, setFieldValue)
											}
										>
											<FontAwesomeIcon
												icon="trash"
												size="lg"
												className="text-white"
											/>
										</button>
									</div>
								)}
							</div>
						))}

						<div className="text-center lg:text-left flex mt-4">
							{isPublic && (
								<button
									type="button"
									className="button-submit block mr-2"
									onClick={() => {
										prev();
									}}
								>
									{`<`} Prev
								</button>
							)}

							<button type="submit" className="button-submit block">
								{isSubmitting || loading ? (
									<FontAwesomeIcon
										icon="spinner"
										size="xl"
										className="text-white animate-spin"
									/>
								) : isPublic ? (
									"Simpan >"
								) : (
									"Save Data"
								)}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormDocumentChecklist;

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./auth.slice";
import profileReducer from "./modules/profile.slice";
import publicReducer from "./modules/public.slice";
import collectReducer from "./modules/collect.slice";

const Store = configureStore({
  reducer: {
    user: userReducer,
    profile: profileReducer,
    public: publicReducer,
    collect: collectReducer,
  },
});

export default Store;

import React from "react";

const TableSailExperience = ({ data }) => {
  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Pemilik
            </th>
            <th scope="col" className="px-6 py-3">
              Nama Kapal
            </th>
            <th scope="col" className="px-6 py-3">
              Negara
            </th>

            <th scope="col" className="px-6 py-3">
              Area
            </th>

            <th scope="col" className="px-6 py-3">
              No Perusahaan
            </th>
            <th scope="col" className="px-6 py-3">
              Tahun Masuk - Tahun Selesai
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={index}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {val?.owner ?? "-"}
              </th>
              <td className="px-6 py-4"> {val?.ship_name ?? "-"}</td>
              <td className="px-6 py-4">{val?.flag ?? "-"}</td>

              <td className="px-6 py-4">{val?.area ?? "-"}</td>
              <td className="px-6 py-4">{val?.no_number_company ?? "-"}</td>
              <td className="px-6 py-4">
                {val?.date_join ?? "-"} - {val?.date_end ?? "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSailExperience;

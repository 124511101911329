import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import boat from "../../assets/boat-bg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { getProfileUser, register } from "../../redux/auth.slice";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const Register = () => {
  const initialState = {
    nama_lengkap: "",
    nik_ktp: "",
    email: "",
    password: "",
    password_confirmation: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { submitting } = useSelector((state) => state.user);

  const [isShow, setIsShow] = useState(false);
  const [isShowCf, setIsShowCf] = useState(false);

  const validationRegister = Yup.object().shape({
    nama_lengkap: Yup.mixed().required("Form ini harus terisi."),
    nik_ktp: Yup.string()
      .min(16, "No.KTP harus 16 karakter.")
      .max(16, "No.KTP harus 16 karakter.")
      .required("Form ini harus terisi."),
    email: Yup.string()
      .email("Email Address tidak valid.")
      .required("Form ini harus terisi."),
    password: Yup.mixed().required("Form ini harus terisi."),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password tidak sama.")
      .required("Form ini harus terisi."),
  });

  const onSubmit = async (values) => {
    const res = await dispatch(register(values));

    if (res.payload.status === true) {
      await dispatch(getProfileUser());
      navigate("/");
    }
  };

  return (
    <section className="h-screen w-screen flex justify-center items-center z-10">
      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center h-full">
          <div className="w-auto">
            <img src={boat} className="w-[40rem]" alt="Sample image" />
          </div>

          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <div className="my-2">
              <h1 className="text-xl font-semibold md:text-2xl text-black">
                Register User
              </h1>
            </div>
            <Formik
              initialValues={initialState}
              onSubmit={onSubmit}
              validationSchema={validationRegister}
            >
              {({
                errors,
                touched,
                values,
                setFieldTouched,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="mb-3">
                    <label>Email</label>
                    <Field
                      className="input-text"
                      name="email"
                      value={values?.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Email..."
                    />
                    {errors.email && touched.email ? (
                      <div className="d-block text-danger">*{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label>Nama Lengkap</label>
                    <Field
                      className="input-text"
                      name="nama_lengkap"
                      value={values?.nama_lengkap}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Nama lengkap..."
                    />
                    {errors.nama_lengkap && touched.nama_lengkap ? (
                      <div className="d-block text-danger">
                        *{errors.nama_lengkap}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label>No.KTP</label>
                    <Field
                      className="input-text"
                      name="nik_ktp"
                      value={values?.nik_ktp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="xxxx xxxx xxxx xxxx"
                    />
                    {errors.nik_ktp && touched.nik_ktp ? (
                      <div className="d-block text-danger">
                        *{errors.nik_ktp}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label>Password</label>
                    <Field
                      type={isShow ? `text` : `password`}
                      className="input-text"
                      name="password"
                      value={values?.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Password..."
                    />
                  </div>

                  <div className="mb-3">
                    <label>Password Confirmation</label>
                    <Field
                      type={isShow ? `text` : `password`}
                      className="input-text"
                      name="password_confirmation"
                      value={values?.password_confirmation}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Password confirmation..."
                    />

                    {isShow ? (
                      <div
                        className="items-center gap-2 py-2 flex cursor-pointer text-[#29B6AE] flex-wrap mb-2"
                        onClick={() => setIsShow(!isShow)}
                      >
                        <EyeSlashIcon className="icon-style" />
                        <small>Hide password</small>
                      </div>
                    ) : (
                      <div
                        className="flex items-center gap-1 cursor-pointer py-2 text-[#29B6AE] flex-wrap"
                        onClick={() => setIsShow(!isShow)}
                      >
                        <EyeIcon className="icon-style" />
                        <small>Show password</small>
                      </div>
                    )}
                    {errors.password && touched.password ? (
                      <div className="d-block text-danger">
                        *{errors.password}
                      </div>
                    ) : null}
                    {errors.password_confirmation &&
                    touched.password_confirmation ? (
                      <div className="d-block text-danger">
                        *{errors.password_confirmation}
                      </div>
                    ) : null}
                  </div>

                  <div className="text-center lg:text-left">
                    <button type="submit" className="button-submit">
                      {submitting ? (
                        <FontAwesomeIcon
                          icon="spinner"
                          size="xl"
                          className="text-white animate-spin"
                        />
                      ) : (
                        "Register"
                      )}
                    </button>
                    <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                      Already have an account?{" "}
                      <NavLink to="/login">
                        <span className="text-yellow-600 cursor-pointer">
                          Login Now!
                        </span>
                      </NavLink>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;

import React from "react";
import { Outlet } from "react-router-dom";

import Topnav from "../components/navs/Topnav";

const AppLayout = () => {
  return (
    <div className="w-screen h-screen">
      <Topnav />

      <main className="w-full h-auto bg-white p-4">
        <div>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AppLayout;

import React from "react";

const TablePendidikan = ({ data }) => {
  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Sekolah
            </th>
            <th scope="col" className="px-6 py-3">
              Jenjang
            </th>
            <th scope="col" className="px-6 py-3">
              Jurusan
            </th>
            <th scope="col" className="px-6 py-3">
              Nilai
            </th>
            <th scope="col" className="px-6 py-3">
              Tahun Mulai - Tahun Selesai
            </th>
            <th scope="col" className="px-6 py-3">
              Kota
            </th>
            <th scope="col" className="px-6 py-3">
              Negara
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={index}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {val?.sekolah ?? "-"}
              </th>
              <td className="px-6 py-4"> {val?.jenjang ?? "-"}</td>
              <td className="px-6 py-4">{val?.jurusan ?? "-"}</td>
              <td className="px-6 py-4">{val?.nilai ?? "-"}</td>
              <td className="px-6 py-4">
                {val?.tahun_mulai ?? "-"}{" "}
                <span className="text-xs text-gray-400">s/d</span>{" "}
                {val?.tahun_selesai ?? "-"}
              </td>
              <td className="px-6 py-4">{val?.kota ?? "-"}</td>
              <td className="px-6 py-4">{val?.negara ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePendidikan;

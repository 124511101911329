export const CurrencyIDR = (amount) => {
  const formattedAmount = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(amount);
  return <span className="text-muted">{formattedAmount}</span>;
};

export const setAccessToken = (payload) => {
  return localStorage.setItem("access_token", payload);
};

export const setRefreshToken = (payload) => {
  return localStorage.setItem("refresh_token", payload);
};
export const getAccessToken = () => {
  return localStorage.getItem("access_token") ?? "";
};
export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token") ?? "";
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("current_user");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("current_user") != null
        ? JSON.parse(localStorage.getItem("current_user"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

export const setUserData = (user) => {
  try {
    if (user) {
      localStorage.setItem("user_data", JSON.stringify(user));
    } else {
      localStorage.removeItem("user_data");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const getUserData = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("user_data") != null
        ? JSON.parse(localStorage.getItem("user_data"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

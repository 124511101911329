import axiosAPI from "../../services/Axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const BASE_PORTAL_CV = `${process.env.REACT_APP_BASE_PORTAL}`;

const initialState = {
  loading: false,
};

  export const collectDataDiri = createAsyncThunk(
    `collect-data-diri`,
    async (data, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-diri`;
        return await axiosAPI
          .post(`${url}`, data)
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataAlamat = createAsyncThunk(
    `collect-data-alamat`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-alamat/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data)
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataKeluarga = createAsyncThunk(
    `collect-data-keluarga`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-keluarga/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data, )
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataKompetensi = createAsyncThunk(
    `collect-data-kompetensi`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-kompetensi/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataProfesi = createAsyncThunk(
    `collect-data-profesi`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-profesi/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataBerlayar = createAsyncThunk(
    `collect-data-berlayar`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-berlayar/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data)
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataTravel = createAsyncThunk(
    `collect-data-traveldoc`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-traveldoc/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const collectDataSupport = createAsyncThunk(
    `collect-data-supportdoc`,
    async (payload, { rejectWithValue }) => {
      try {
        const url = `${BASE_PORTAL_CV}/collect-data-supportdoc/${payload.id}`;
        return await axiosAPI
          .post(`${url}`, payload.data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => response.data);
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  const collectSlice = createSlice({
    name: "collect",
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
  });
  
  export default collectSlice.reducer;
import Swal from "sweetalert2";

export const SwalSuccess = (title, text) => {
  return Swal.fire(`${title}`, `${text}`, "success");
};

export const SwalError = (title, text) => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  });
};

export const SwalConfirmation = (title, text, type) => {
  return Swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `Yes, ${type}  it!`,
  });
};

import React from "react";
import { useSelector } from "react-redux";

const MyApplication = () => {
  const { userData } = useSelector((state) => state.user);
  return (
    <div className="container  mx-auto max-w-7xl ">
      <div className="grid grid-cols-12">
        {/* Header */}
        <div className="col-span-12 card-header mb-4">
          <h1 className="text-xl">My Application</h1>
        </div>
        {/* Content */}
        <div className="col-span-12  mx-auto card max-w-4xl"></div>
      </div>
    </div>
  );
};

export default MyApplication;

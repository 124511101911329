import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { deletePekerjaan, setPekerjaan } from "../../redux/modules/profile.slice";
import { CurrencyIDR } from "../../services/Utils";
import {
	getPositionPublic,
	getVehiclePublic,
} from "../../redux/modules/public.slice";
import { updateSertifikasiPekerjaan } from "../../redux/auth.slice";
import { setBerlayarCrew } from "../../services/CVStorage";
import Swal from "sweetalert2";
import { collectDataBerlayar } from "../../redux/modules/collect.slice";
import { toast } from "react-hot-toast";

const FormPekerjaan = ({ isSubmitting, data, isPublic, next, prev }) => {
	const initialState = {
		pekerjaan:
			data?.length > 0
				? data
				: [
						{
							jabatan: "",
							nama_kapal: "",
							no_tlp_perusahaan: null,
							perusahaan: "",
							lokasi: "",
							negara: "",
							bendera_kapal: "",
							area_operasi: "",
							tahun_masuk: "",
							tahun_berhenti: "",
							gaji: null,
							alasan_berhenti: "",
							fasilitas: "",
							pemberi_rekomendasi: "",
							kontak_pemberi_informasi: "",
							deskripsi_pekerjaan: "",
						},
				  ],
	};
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false)

	const validationPekerjaan = Yup.object().shape({
		pekerjaan: Yup.array().of(
			Yup.object().shape({
				jabatan: Yup.mixed().required("Form ini harus terisi."),
				perusahaan: Yup.mixed().required("Form ini harus terisi."),
				lokasi: Yup.mixed().required("Form ini harus terisi."),
				negara: Yup.mixed().required("Form ini harus terisi."),
				tahun_masuk: Yup.date().required("Form ini harus terisi."),
				// tahun_berhenti: Yup.date().required("Form ini harus terisi."),
				gaji: Yup.mixed().required("Form ini harus terisi."),
				deskripsi_pekerjaan: Yup.mixed().required("Form ini harus terisi."),
			})
		),
	});

	const eventAddData = (values, setFieldValue) => {
		let newArr = [...values.pekerjaan];
		newArr.push({
			jabatan: "",
			nama_kapal: "",
			no_tlp_perusahaan: "",
			perusahaan: "",
			lokasi: "",
			negara: "",
			bedera_kapal: "",
			area_operasi: "",
			tahun_masuk: "",
			tahun_berhenti: "",
			gaji: null,
			alasan_berhenti: "",
			fasilitas: "",
			pemberi_rekomendasi: "",
			kontak_pemberi_informasi: "",
			deskripsi_pekerjaan: "",
		});
		setFieldValue("pekerjaan", newArr);
	};

	const onDelete = (values, index, setFieldValue) => {
		if(!values.pekerjaan[index].id){
		  return  setFieldValue(
			"pekerjaan",
			values.pekerjaan.filter((_, i) => i !== index)
		)
		}
  
		Swal.fire({
		  title: 'Apakah yakin ingin menghapus data ini?',
		  text: "Data yang terhapus tidak akan kembali.",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Ya, Hapus!'
		}).then(async (result) => {
		  if (result.isConfirmed) {
  
			const res = await dispatch(deletePekerjaan(values.pekerjaan[index].id))
  
			if(res.payload.status === true){
				setFieldValue(
					"pekerjaan",
					values.pekerjaan.filter((_, i) => i !== index)
				  )
		
				  Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				  )
			}
		  }
		})
	}

	const onSubmit = async (values) => {
		if (isPublic) {
			setBerlayarCrew(values);
				
			setLoading(true)

			let formData = new FormData()

			values?.pekerjaan?.forEach((item, index) => {
			  for (const property in item) {
				formData.append(`pekerjaan[${index}][${property}]`, item[property]);
			  }
			});
	  
			let user_id = localStorage.getItem('user_id')
	  
			const res = await dispatch(collectDataBerlayar({id: Number(user_id), data:formData}))
	  
			if(res.payload.data.status === false || res.payload.status === 400){
			  setLoading(false)
			  toast.error(res.payload.data.data);
			  return 
			}
			toast.success('Simpan data pekerjaan berhasil.');
			setLoading(false)
	  
			return next();
		}

		const res = await dispatch(setPekerjaan(values));

		if (res.payload.status === true) {
			dispatch(updateSertifikasiPekerjaan(values.pekerjaan));
		}
	};

	return (
		<>
			<div className="flex mb-4">
				<div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
					<h1 className="text-xl text-white font-bold ">Pengalaman Berlayar</h1>
				</div>
			</div>
			<Formik
				initialValues={initialState}
				onSubmit={onSubmit}
				validationSchema={validationPekerjaan}
			>
				{({
					errors,
					touched,
					values,
					setFieldTouched,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => (
					<Form className="text-sm">
						{values?.pekerjaan?.map((val, index) => (
							<div
								className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
								key={index}
							>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Nama Perusahaan</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].perusahaan`}
										id={`pekerjaan[${index}].perusahaan`}
										value={values?.pekerjaan?.[index]?.perusahaan}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].perusahaan`,
												e.target.value
											);
										}}
										placeholder="Nama perusahaan..."
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].perusahaan`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Lokasi</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].lokasi`}
										id={`pekerjaan[${index}].lokasi`}
										value={values?.pekerjaan?.[index]?.lokasi}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].lokasi`,
												e.target.value
											);
										}}
										placeholder="Lokasi..."
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].lokasi`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Negara</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].negara`}
										id={`pekerjaan[${index}].negara`}
										value={values?.pekerjaan?.[index]?.negara}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].negara`,
												e.target.value
											);
										}}
										placeholder="Negara..."
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].negara`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Nama Kapal</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].nama_kapal`}
										id={`pekerjaan[${index}].nama_kapal`}
										value={values?.pekerjaan?.[index]?.nama_kapal}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].nama_kapal`,
												e.target.value
											);
										}}
										placeholder="Nama Kapal..."
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].nama_kapal`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Bendera Kapal</label>
									<Field
										type="text"
										className="input-text  "
										name={`pekerjaan[${index}].bendera_kapal`}
										id={`pekerjaan[${index}].bendera_kapal`}
										value={values?.pekerjaan?.[index]?.bendera_kapal}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].bendera_kapal`,
												e.target.value
											);
										}}
										placeholder="Nama Bendera Kapal..."
									/>

									<ErrorMessage
										name={`pekerjaan[${index}].bendera_kapal`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-4">
									<label>Area Operasi</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].area_operasi`}
										id={`pekerjaan[${index}].area_operasi`}
										value={values?.pekerjaan?.[index]?.area_operasi}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].area_operasi`,
												e.target.value
											);
										}}
										placeholder="Area Operasi..."
									/>

									<ErrorMessage
										name={`pekerjaan[${index}].area_operasi`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-2">
									<label>Tahun Masuk</label>
									<Field
										type="date"
										className="input-text  "
										name={`pekerjaan[${index}].tahun_masuk`}
										id={`pekerjaan[${index}].tahun_masuk`}
										value={values?.pekerjaan?.[index]?.tahun_masuk}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].tahun_masuk`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].tahun_masuk`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-2">
									<label>Tahun Selesai</label>
									<Field
										type="date"
										className="input-text  "
										name={`pekerjaan[${index}].tahun_berhenti`}
										id={`pekerjaan[${index}].tahun_berhenti`}
										value={values?.pekerjaan?.[index]?.tahun_berhenti}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].tahun_berhenti`,
												e.target.value
											);
										}}
										placeholder="YYYY"
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].tahun_berhenti`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Jabatan</label>
									<Field
										type="text"
										className="input-text  "
										name={`pekerjaan[${index}].jabatan`}
										id={`pekerjaan[${index}].jabatan`}
										value={values?.pekerjaan?.[index]?.jabatan}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].jabatan`,
												e.target.value
											);
										}}
										placeholder="Jabatan..."
									/>
									<ErrorMessage
										name={`pekerjaan[${index}].jabatan`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Gaji</label>
									<Field
										type="number"
										className="input-text  "
										name={`pekerjaan[${index}].gaji`}
										id={`pekerjaan[${index}].gaji`}
										value={values?.pekerjaan?.[index]?.gaji}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(`pekerjaan[${index}].gaji`, e.target.value);
										}}
										placeholder="Gaji..."
									/>
									<span className="text-slate-500 text-xs">
										{" "}
										{CurrencyIDR(values?.pekerjaan?.[index]?.gaji)}
									</span>
									<ErrorMessage
										name={`pekerjaan[${index}].gaji`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Fasilitas</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].fasilitas`}
										id={`pekerjaan[${index}].fasilitas`}
										value={values?.pekerjaan?.[index]?.fasilitas}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].fasilitas`,
												e.target.value
											);
										}}
										placeholder="Fasilitas..."
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Alasan Berhenti</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].alasan_berhenti`}
										id={`pekerjaan[${index}].alasan_berhenti`}
										value={values?.pekerjaan?.[index]?.alasan_berhenti}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].alasan_berhenti`,
												e.target.value
											);
										}}
										placeholder="Alasan berhenti..."
									/>
								</div>

								<div className="mb-3       col-span-6 sm:col-span-4">
									<label>Pemberi Rekomendasi</label>
									<Field
										className="input-text  "
										name={`pekerjaan[${index}].pemberi_rekomendasi`}
										id={`pekerjaan[${index}].pemberi_rekomendasi`}
										value={values?.pekerjaan?.[index]?.pemberi_rekomendasi}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].pemberi_rekomendasi`,
												e.target.value
											);
										}}
										placeholder="Pemberi Rekomendasi..."
									/>
								</div>
								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Kontak Pemberi Rekomendasi</label>
									<Field
										className="input-text  "
										type="number"
										name={`pekerjaan[${index}].kontak_pemberi_informasi`}
										id={`pekerjaan[${index}].kontak_pemberi_informasi`}
										value={values?.pekerjaan?.[index]?.kontak_pemberi_informasi}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].kontak_pemberi_informasi`,
												e.target.value
											);
										}}
										placeholder="Kontak Pemberi Rekomendasi..."
									/>
								</div>
								<div className="mb-3       col-span-6 sm:col-span-4">
									<label>No Telp Perusahaan</label>
									<Field
										type="number"
										className="input-text  "
										name={`pekerjaan[${index}].no_tlp_perusahaan`}
										id={`pekerjaan[${index}].no_tlp_perusahaan`}
										value={values?.pekerjaan?.[index]?.no_tlp_perusahaan}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].no_tlp_perusahaan`,
												e.target.value
											);
										}}
										placeholder="No Telp Perusahaan..."
									/>

									<ErrorMessage
										name={`pekerjaan[${index}].no_tlp_perusahaan`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								<div className="mb-3        col-span-6 sm:col-span-4">
									<label>Deskripsi Pekerjaan</label>
									<Field
										className="input-text  "
										component="textarea"
										rows={4}
										name={`pekerjaan[${index}].deskripsi_pekerjaan`}
										id={`pekerjaan[${index}].deskripsi_pekerjaan`}
										value={values?.pekerjaan?.[index]?.deskripsi_pekerjaan}
										onBlur={handleBlur}
										onChange={(e) => {
											setFieldValue(
												`pekerjaan[${index}].deskripsi_pekerjaan`,
												e.target.value
											);
										}}
										placeholder="Deskripsi pekerjaan..."
									/>

									<ErrorMessage
										name={`pekerjaan[${index}].deskripsi_pekerjaan`}
										component="div"
										style={{ color: "red" }}
									/>
								</div>

								{index === 0 ? (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Tambah</label>
										<button
											type="button"
											className="button-submit block mb-4"
											onClick={() => eventAddData(values, setFieldValue)}
										>
											<FontAwesomeIcon
												icon="plus"
												size="lg"
												className="text-white "
											/>
										</button>
									</div>
								) : (
									<div className="col-span-1">
										<label className="text-slate-600 text-sm">Hapus</label>
										<button
											type="button"
											className="button-submit block mb-4 bg-red-500"
											onClick={() =>
												onDelete(values, index, setFieldValue)
											}
										>
											<FontAwesomeIcon
												icon="trash"
												size="lg"
												className="text-white"
											/>
										</button>
									</div>
								)}
							</div>
						))}

						<div className="text-center lg:text-left flex mt-4">
							{isPublic && (
								<button
									type="button"
									className="button-submit block mr-2"
									onClick={() => {
										prev();
									}}
								>
									{`<`} Prev
								</button>
							)}

							<button type="submit" className="button-submit block">
								{isSubmitting || loading ? (
									<FontAwesomeIcon
										icon="spinner"
										size="xl"
										className="text-white animate-spin"
									/>
								) : isPublic ? (
									"Simpan >"
								) : (
									"Save Data"
								)}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormPekerjaan;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UserGroupIcon,
  BookOpenIcon,
  DocumentDuplicateIcon,
  UsersIcon,
  BriefcaseIcon,
  DocumentCheckIcon,
  PencilSquareIcon,
  DocumentTextIcon,
  RocketLaunchIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";

import userFemale from "../../../assets/user/11.png";
import userMale from "../../../assets/user/09.jpg";
import TableKeluarga from "../../../containers/profile/TableKeluarga";
import TableOrganisasi from "../../../containers/profile/TableOrganisasi";
import TableDokumenPenting from "../../../containers/profile/TableDokumenPenting";
import TablePekerjaan from "../../../containers/profile/TablePekerjaan";
import TablePendidikan from "../../../containers/profile/TablePendidikan";
import TableSailExperience from "../../../containers/profile/TableSailExperience";
import TableTravelDocument from "../../../containers/profile/TableTravelDocument";
import TableSertifikasiProficiency from "../../../containers/profile/TableSertifikasiProficiency";
import TableSertifikasiCompetency from "../../../containers/profile/TableSertifikasiCompetency";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/auth.slice";
import { SwalSuccess } from "../../../components/notification/Swal";
import PdfLink from "../../../helper/PDFLink";

const Profile = () => {
  const { userData } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("keluarga");

  return (
    <div className="container  mx-auto max-w-7xl ">
      <div className="grid grid-cols-4 gap-4  mb-5">
        <div className="  flex flex-col mx-auto col-span-1  items-center gap-2 h-auto border-slate-300 ">
          <img
            src={
              userData?.personal_data?.photo ? userData?.personal_data?.photo_url : userData?.personal_data?.gender === "Male"
                ? userMale
                : userFemale
            }
            className="w-[10rem] h-[10rem] rounded-full border"
            alt=""
          />
          <button
            className="button-submit flex"
            onClick={() => navigate("/edit-profile")}
          >
            <PencilSquareIcon className="icon-style mr-2" /> Edit Profile
          </button>
          <button
            className="button-submit flex bg-red-500 items-end hover:bg-red-800"
            onClick={() => {
              dispatch(logout());
              SwalSuccess(
                "Logout Berhasil.",
                "User berhasil melakukan logout."
              );
            }}
          >
            <ArrowLeftOnRectangleIcon className="icon-style mr-2 text-white" />{" "}
            Logout
          </button>
        </div>
        <div className="col-span-3">
          <div className="grid grid-rows-2 ">
            <div className="row-span-1 mb-2 rounded-md card">
              <div className="grid grid-cols-1">
                <h3 className="font-bold text-xl">Data Diri</h3>
              </div>
              <div className="grid grid-cols-2">
                <div className="col-span-1">
                  <table className="border-separate border-spacing-4">
                    <tbody className="text-sm font-normal">
                      <tr>
                        <td>Nama Lengkap</td>
                        <td>: {userData?.personal_data?.full_name ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Nama Panggilan</td>
                        <td>: {userData?.personal_data?.nick_name ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>: {userData?.email ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No Handphone</td>
                        <td>: {userData?.personal_data?.hp_number ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td>: {userData?.personal_data?.gender ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Tempat, Tanggal Lahir</td>
                        <td>
                          : {userData?.personal_data?.place_of_birth ?? "-"},{" "}
                          {userData?.personal_data?.date_of_birth ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Usia</td>
                        <td>: {userData?.personal_data?.age ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Jabatan</td>
                        <td>
                          : {userData?.personal_data?.position?.name ?? "-"}
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>Nama Pemilik Bank</td>
                        <td>
                          : {userData?.personal_data?.rekening_name ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Bank</td>
                        <td>: {userData?.personal_data?.bank?.name ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No Rekening</td>
                        <td>
                          : {userData?.personal_data?.rekening_number ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>CV</td>
                        <td>
                          : <PdfLink url={userData?.personal_data?.cv_file_url} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-span-1">
                  <table className="border-separate border-spacing-4">
                    <tbody className="text-sm font-normal">
                      <tr>
                        <td>Agama</td>
                        <td>
                          : {userData?.personal_data?.religion?.name ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Status Pernikahan</td>
                        <td>
                          :{" "}
                          {userData?.personal_data?.marital_status?.name ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Golongan Darah</td>
                        <td>
                          :{" "}
                          {userData?.personal_data?.blood_type?.alias}
                        </td>
                      </tr>
                      <tr>
                        <td>Kewarganegaraan</td>
                        <td>: {userData?.personal_data?.citizenship ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No KTP</td>
                        <td>: {userData?.personal_data?.nik_ktp ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No KK</td>
                        <td>: {userData?.personal_data?.no_kk ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Seafarer Code</td>
                        <td>
                          : {userData?.personal_data?.seaferer_code ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Seaman Book Number</td>
                        <td>
                          : {userData?.personal_data?.seaman_book_number ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Passport Number</td>
                        <td>
                          : {userData?.personal_data?.passport_number ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>No NPWP</td>
                        <td>: {userData?.personal_data?.npwp_number ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No BPJS Kesehatan</td>
                        <td>
                          : {userData?.personal_data?.bpjs_kes_number ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>No BPJS Ketenagakerjaan</td>
                        <td>
                          : {userData?.personal_data?.bpjs_tk_number ?? "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row-span-1 rounded-md card">
              <div className="grid grid-cols-1">
                <h3 className="font-bold text-xl">Alamat</h3>
              </div>
              <div className="grid grid-cols-2">
                <div className="col-span-1">
                  <table className="border-separate border-spacing-4">
                    <tbody className="text-sm font-normal">
                      <tr>
                        <td className="text-lg "> KTP</td>
                      </tr>
                      <tr>
                        <td>Provinsi</td>
                        <td>: {userData?.alamat_ktp?.[0]?.provinsi ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Kabupaten</td>
                        <td>: {userData?.alamat_ktp?.[0]?.kabupaten ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td>: {userData?.alamat_ktp?.[0]?.kecamatan ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Kelurahan</td>
                        <td>: {userData?.alamat_ktp?.[0]?.kelurahan ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td>: {userData?.alamat_ktp?.[0]?.alamat ?? "-"}</td>
                      </tr>

                      <tr>
                        <td>No.Rumah</td>
                        <td>: {userData?.alamat_ktp?.[0]?.no_rumah ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>Kode Pos</td>
                        <td>: {userData?.alamat_ktp?.[0]?.kode_pos ?? "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-span-1">
                  <table className="border-separate border-spacing-4">
                    <tbody className="text-sm font-normal">
                      <tr>
                        <td className="text-lg ">Domisili</td>
                      </tr>
                      <tr>
                        <td>Provinsi</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.provinsi ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Kabupaten</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.kabupaten ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.kecamatan ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Kelurahan</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.kelurahan ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.alamat ?? "-"}
                        </td>
                      </tr>

                      <tr>
                        <td>No.Rumah</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.no_rumah ?? "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Kode Pos</td>
                        <td>
                          : {userData?.alamat_domisili?.[0]?.kode_pos ?? "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 card">
        {/* navigation tabs */}
        <div className="border-b border-gray-200 dark:border-gray-700 mb-[2rem]">
          <ul className="flex  flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "keluarga"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("keluarga")}
              >
                {" "}
                <UserGroupIcon className="icon-style mr-2" />
                Keluarga
              </button>
            </li>
            {/* <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "pendidikan"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("pendidikan")}
              >
                {" "}
                <BookOpenIcon className="icon-style mr-2" />
                Pendidikan
              </button>
            </li> */}
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "sertifikasi-competency"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("sertifikasi-competency")}
              >
                {" "}
                <DocumentDuplicateIcon className="icon-style mr-2" />
                Sertifikasi Kompetensi
              </button>
            </li>
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "sertifikasi-proficiency"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("sertifikasi-proficiency")}
              >
                {" "}
                <DocumentDuplicateIcon className="icon-style mr-2" />
                Sertifikasi Profesi
              </button>
            </li>

            {/* <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "organisasi"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("organisasi")}
              >
                {" "}
                <UsersIcon className="icon-style mr-2" />
                Riwayat Organisasi
              </button>
            </li> */}
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "pekerjaan"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("pekerjaan")}
              >
                {" "}
                <BriefcaseIcon className="icon-style mr-2" />
                Pengalaman Berlayar
              </button>
            </li>
            {/* <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "sail-experience"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("sail-experience")}
              >
                {" "}
                <RocketLaunchIcon className="icon-style mr-2" />
                Sail Experience
              </button>
            </li> */}
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "travel-document"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("travel-document")}
              >
                {" "}
                <DocumentTextIcon className="icon-style mr-2" />
                Dokumen Perjalanan
              </button>
            </li>
            <li className="mr-2">
              <button
                className={`inline-flex p-4 border-b-2 group rounded-t-lg  ${
                  activeTab === "dokumen-penting"
                    ? "text-[#29B6AE]  border-[#29B6AE]"
                    : " text-slate-400 border-slate-400"
                } `}
                onClick={() => setActiveTab("dokumen-penting")}
              >
                <DocumentCheckIcon className="icon-style mr-2" />
                Dokumen Penting
              </button>
            </li>
          </ul>
        </div>

        {/* content tabs */}

        {activeTab === "keluarga" && (
          <div>
            {/* TABLE KELUARGA */}
            {userData?.family && userData?.family?.length > 0 ? (
              <TableKeluarga data={userData?.family} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA KELUARGA
              </div>
            )}
          </div>
        )}
        {activeTab === "pendidikan" && (
          <div>
            {/* TABLE PENDIDIKAN */}
            {userData?.pendidikan && userData?.pendidikan?.length > 0 ? (
              <TablePendidikan data={userData?.pendidikan} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA PENDIDIKAN
              </div>
            )}
          </div>
        )}

        {activeTab === "sertifikasi-proficiency" && (
          <div>
            {/* TABLE SERTIFIKASI */}
            {userData?.certificate_proficiency &&
            userData?.certificate_proficiency?.length > 0 ? (
              <TableSertifikasiProficiency
                data={userData?.certificate_proficiency}
              />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA SERTIFIKAAT PROFICIENCY
              </div>
            )}
          </div>
        )}
        {activeTab === "sertifikasi-competency" && (
          <div>
            {/* TABLE SERTIFIKASI */}
            {userData?.certificate_competency &&
            userData?.certificate_competency?.length > 0 ? (
              <TableSertifikasiCompetency
                data={userData?.certificate_competency}
              />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA SERTIFIKAAT COMPETENCY
              </div>
            )}
          </div>
        )}

        {/* {activeTab === "organisasi" && (
          <div>
            {userData?.organisasi && userData?.organisasi?.length > 0 ? (
              <TableOrganisasi data={userData?.organisasi} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA ORGANISASI
              </div>
            )}
          </div>
        )} */}

        {activeTab === "pekerjaan" && (
          <div>
            {/* TABLE PEKERJAAN*/}
            {userData?.pekerjaan && userData?.pekerjaan?.length > 0 ? (
              <TablePekerjaan data={userData?.pekerjaan} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA PEKERJAAN
              </div>
            )}
          </div>
        )}

        {activeTab === "sail-experience" && (
          <div>
            {/* TABLE SAIL EXP*/}
            {userData?.sail_experience &&
            userData?.sail_experience?.length > 0 ? (
              <TableSailExperience data={userData?.sail_experience} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA SAIL EXPERIENCE
              </div>
            )}
          </div>
        )}

        {activeTab === "travel-document" && (
          <div>
            {/* TABLE TRAVEL DOCUMENT*/}
            {userData?.travel_document &&
            userData?.travel_document?.length > 0 ? (
              <TableTravelDocument data={userData?.travel_document} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA Travel Document
              </div>
            )}
          </div>
        )}

        {activeTab === "dokumen-penting" && (
          <div>
            {/* TABLE DOKUMEN PENTING*/}
            {userData?.support_document &&
            userData?.support_document?.length > 0 ? (
              <TableDokumenPenting data={userData?.support_document} />
            ) : (
              <div className="text-xl text-slate-400 uppercase flex items-center justify-center w-full">
                BELUM ADA DATA Dokumen Penting
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;

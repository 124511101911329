import React from "react";
import { useSelector } from "react-redux";

const CareerOpportunity = () => {
  const { userData } = useSelector((state) => state.user);
  return (
    <div className="container  mx-auto max-w-7xl ">
      <div className="grid grid-cols-12">
        {/* Header */}
        <div className="col-span-12 card-header mb-4">
          <h1 className="text-xl">Career Opportunities</h1>
        </div>
        {/* Content */}
        <div className="col-span-12  mx-auto card max-w-4xl">
          <div className="flex flex-col w-auto text-justify text-sm gap-4">
            <h5 className="text-lg">Perhatian</h5>
            <p>
              PT.Transcoal Pacific hanya membuka lowongan pekerjaan secara
              online dalam laman ini dan beberapa situs pencari kerja ternama,
              serta tidak akan pernah memungut biaya apapun dalam proses
              rekrutmennya.
              <br />
              <br />
              Kepada para calon pelamar diharapkan lebih seksama dan cermat saat
              melihat info lowongan pekerjaan yang mengatasnamakan PT.Transcoal
              Pacific. Jika terdapat keraguan atau pertanyaan mengenai info
              lowongan pekerjaan atau pemanggilan proses rekrutmen, dapat
              menghubungi kami melalui nomor telepon +6221-7814 948 atau email:
              recruitment@transcoalpacific.co.id
              <br />
              <br />
              Jika posisi yang kamu inginkan tidak ada, tetaplah mengisi cv
              online, untuk kami simpan di database dan akan kami proses jika
              dibutuhkan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerOpportunity;

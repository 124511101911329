import axios from "axios";
import { notifyError } from "../components/notification/Toast";
import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
} from "../services/Utils";

/**
 * Create Axios instance.
 */

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

/**
 * Default request interceptor axios.
 */
AxiosInstance.interceptors.request.use(
  (config) => {
    if (config.url !== "auth/login")
      config.headers["Authorization"] = `Bearer ${getAccessToken()}`;
    // if (config.url === 'auth/logout') config.headers['Authorization'] = `bearer ${getRefreshToken()}`;
    return config;
  },
  (errors) => {
    return Promise.reject(errors);
  }
);

/**
 * Default response interceptors axios.
 */
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (errors) => {
    /**
     * IF THE REQUEST ERROR GET STATUS CODE : 401
     * THEN CHECK THE MESSAGES ERROR.
     * IF THE MESSAGES ERROR IS EXPIRED TOKEN.
     * THEN REFRESH TOKEN AND RUNING AGAIN AXIOS WITH BEFORE CONFIGURATION
     */
    let oldConfigAxios = errors.config;
    const statusCode = errors?.response?.status;
    const messageErrors = errors?.response?.data?.data;

    if (messageErrors === "Token expired" || messageErrors === "Token not found" || messageErrors === "Token invalid") {
      localStorage.clear()
  
      notifyError("Token Expired Mohon Login Kembali.");

     setTimeout(() => {
      window.location.href = '/login';
    }, 1000)
    }
    return Promise.reject(errors);
  }
);

const axiosInstance = AxiosInstance;

export { axiosInstance };

export default AxiosInstance;

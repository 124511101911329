import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setSailExperience } from "../../redux/modules/profile.slice";
import { getPositionPublic } from "../../redux/modules/public.slice";
import { updateSertifikasiSailExperience } from "../../redux/auth.slice";

const FormSailExperience = ({ isSubmitting, data }) => {
  const initialState = {
    sail_experience:
      data.length > 0
        ? data
        : [
            {
              owner: "",
              position_id: null,
              ship_name: "",
              vessel_flag: "",
              flag: "",
              area: "",
              no_number_company: "",
              date_join: "",
              date_end: "",
            },
          ],
  };

  const [positionOptions, setPositionOptions] = useState([]);

  const dispatch = useDispatch();

  const validationsail_experience = Yup.object().shape({
    sail_experience: Yup.array().of(
      Yup.object().shape({
        owner: Yup.mixed().required("Form ini harus terisi."),
        position_id: Yup.mixed().required("Form ini harus terisi."),
        ship_name: Yup.mixed().required("Form ini harus terisi."),
        vessel_flag: Yup.mixed().required("Form ini harus terisi."),
        date_join: Yup.date().required("Form ini harus terisi."),
        date_end: Yup.date().required("Form ini harus terisi."),
        flag: Yup.mixed().required("Form ini harus terisi."),
        no_number_company: Yup.mixed().required("Form ini harus terisi."),
        area: Yup.mixed().required("Form ini harus terisi."),
      })
    ),
  });

  useEffect(() => {
    const fetchListPosition = async () => {
      const res = await dispatch(getPositionPublic());

      if (res.payload.status) {
        let tempArr = [];
        res.payload.data.map((val) => {
          tempArr.push({
            value: val.id_position,
            label: val.name_position,
          });
        });

        setPositionOptions(tempArr);
      }
    };

    fetchListPosition();
  }, []);

  const eventAddData = (values, setFieldValue) => {
    let newArr = [...values.sail_experience];
    newArr.push({
      owner: "",
      position_id: null,
      ship_name: "",
      vessel_flag: "",
      flag: "",
      area: "",
      no_number_company: "",
      date_join: "",
      date_end: "",
    });
    setFieldValue("sail_experience", newArr);
  };

  const onSubmit = async (values) => {

    const res = await dispatch(setSailExperience(values));

    if (res.payload.status === true) {

      dispatch(updateSertifikasiSailExperience(values.sail_experience));
    }
  };

  return (
    <>
      <div className="flex mb-4">
        <div className="bg-[#29B6AE] p-2 inline-block px-4 rounded-md">
          <h1 className="text-xl text-white font-bold ">
            Form Sail Experience
          </h1>
        </div>
      </div>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validationSchema={validationsail_experience}
      >
        {({
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="text-sm">
            {values?.sail_experience?.map((val, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-b-[1px] border-[#29B6AE] mb-4"
                key={index}
              >
                <div className="mb-3 col-span-4">
                  <label>Pemilik Kapal</label>

                  <Field
                    className="input-text  "
                    name={`sail_experience[${index}].owner`}
                    id={`sail_experience[${index}].owner`}
                    value={values?.sail_experience?.[index]?.owner}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].owner`,
                        e.target.value
                      );
                    }}
                    placeholder="Pemilik kapal..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].owner`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-4">
                  <label>Nama Kapal</label>
                  <Field
                    type="text"
                    className="input-text  "
                    name={`sail_experience[${index}].ship_name`}
                    id={`sail_experience[${index}].ship_name`}
                    value={values?.sail_experience?.[index]?.ship_name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].ship_name`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama Kapal..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].ship_name`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-4">
                  <label>Jabatan</label>
                  <select
                    className="input-text"
                    name={`sail_experience[${index}].position_id`}
                    id={`sail_experience[${index}].position_id`}
                    value={values?.sail_experience?.[index]?.position_id}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].position_id`,
                        e.target.value
                      );
                    }}
                    placeholder="Jenis Kelamin..."
                  >
                    <option value="">Select Options...</option>
                    {positionOptions.map((val, index) => (
                      <option key={index} value={val.value}>
                        {val.label}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name={`sail_experience[${index}].negara`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-2">
                  <label>Tahun Masuk</label>
                  <Field
                    type="date"
                    className="input-text  "
                    name={`sail_experience[${index}].date_join`}
                    id={`sail_experience[${index}].date_join`}
                    value={values?.sail_experience?.[index]?.date_join}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].date_join`,
                        e.target.value
                      );
                    }}
                    placeholder="YYYY"
                  />
                  <ErrorMessage
                    name={`sail_experience[${index}].date_join`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="mb-3 col-span-2">
                  <label>Tahun Selesai</label>
                  <Field
                    type="date"
                    className="input-text  "
                    name={`sail_experience[${index}].date_end`}
                    id={`sail_experience[${index}].date_end`}
                    value={values?.sail_experience?.[index]?.date_end}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].date_end`,
                        e.target.value
                      );
                    }}
                    placeholder="YYYY"
                  />
                  <ErrorMessage
                    name={`sail_experience[${index}].date_end`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-4">
                  <label>Vessel Flag</label>
                  <Field
                    type="text"
                    className="input-text  "
                    name={`sail_experience[${index}].vessel_flag`}
                    id={`sail_experience[${index}].vessel_flag`}
                    value={values?.sail_experience?.[index]?.vessel_flag}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].vessel_flag`,
                        e.target.value
                      );
                    }}
                    placeholder="Nama Vessel Flag..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].vessel_flag`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-2">
                  <label>Negara</label>
                  <Field
                    className="input-text  "
                    name={`sail_experience[${index}].flag`}
                    id={`sail_experience[${index}].flag`}
                    value={values?.sail_experience?.[index]?.flag}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].flag`,
                        e.target.value
                      );
                    }}
                    placeholder="Negara..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].flag`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-2">
                  <label>Area</label>
                  <Field
                    className="input-text  "
                    name={`sail_experience[${index}].area`}
                    id={`sail_experience[${index}].area`}
                    value={values?.sail_experience?.[index]?.area}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].area`,
                        e.target.value
                      );
                    }}
                    placeholder="Area..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].area`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="mb-3 col-span-11">
                  <label>No Telepon Perusahaan</label>
                  <Field
                    type="number"
                    className="input-text  "
                    name={`sail_experience[${index}].no_number_company`}
                    id={`sail_experience[${index}].no_number_company`}
                    value={values?.sail_experience?.[index]?.no_number_company}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        `sail_experience[${index}].no_number_company`,
                        e.target.value
                      );
                    }}
                    placeholder="No perusahaan..."
                  />

                  <ErrorMessage
                    name={`sail_experience[${index}].no_number_company`}
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                {index === 0 ? (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Tambah</label>
                    <button
                      type="button"
                      className="button-submit block mb-4"
                      onClick={() => eventAddData(values, setFieldValue)}
                    >
                      <FontAwesomeIcon
                        icon="plus"
                        size="lg"
                        className="text-white "
                      />
                    </button>
                  </div>
                ) : (
                  <div className="col-span-1">
                    <label className="text-slate-600 text-sm">Hapus</label>
                    <button
                      type="button"
                      className="button-submit block mb-4 bg-red-500"
                      onClick={() =>
                        setFieldValue(
                          "sail_experience",
                          values.sail_experience.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon="trash"
                        size="lg"
                        className="text-white"
                      />
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className="text-center lg:text-left">
              <button type="submit" className="button-submit block">
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    size="xl"
                    className="text-white animate-spin"
                  />
                ) : (
                  "Save Data"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormSailExperience;

import axiosAPI from "../services/Axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAccessToken,
  getCurrentUser,
  getRefreshToken,
  getUserData,
  setAccessToken,
  setCurrentUser,
  setRefreshToken,
  setUserData,
} from "../services/Utils";
import { notifyError } from "../components/notification/Toast";
import { SwalSuccess } from "../components/notification/Swal";

const BASE_PORTAL_CV = `${process.env.REACT_APP_BASE_PORTAL}`;
const BASE_URL_PROFILE = `${process.env.REACT_APP_BASE_PORTAL}/recruitment/dashboard/cv`;

const initialState = {
  loading: false,
  currentUser: getCurrentUser() ?? null,
  error: "",
  access_token: getAccessToken(),
  isLoggedIn: false,
  submitting: false,
  userData: getUserData() ?? null,
};

export const getProfileUser = createAsyncThunk(`get-user`, async () => {
  return await axiosAPI
    .get(`${BASE_URL_PROFILE}/get-profile`)
    .then((response) => response.data);
});

// Generates pending, fulfilled and rejected action types
export const login = createAsyncThunk(
  `user-login`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/auth/login`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const register = createAsyncThunk(
  `user-register`,
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_PORTAL_CV}/auth/register`;
      return await axiosAPI
        .post(`${url}`, data)
        .then((response) => response.data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.currentUser = null;
      state.isLoggedIn = false;
      state.access_token = null;
      state.userData = null;

      window.localStorage.clear();
    },
    updateFamily: (state, data) => {
      state.userData.family = data.payload;
    },
    updatePendidikan: (state, data) => {
      state.userData.pendidikan = data.payload;
    },
    updateSertifikasiProficiency: (state, data) => {
      state.userData.certificate_proficiency = data.payload;
    },
    updateSertifikasiCompetency: (state, data) => {
      state.userData.certificate_competency = data.payload;
    },
    updateSertifikasiOrganisasi: (state, data) => {
      state.userData.organisasi = data.payload;
    },
    updateSertifikasiPekerjaan: (state, data) => {
      state.userData.pekerjaan = data.payload;
    },
    updateSertifikasiSailExperience: (state, data) => {
      state.userData.sail_experience = data.payload;
    },
    updateSertifikasiTravelDocument: (state, data) => {
      state.userData.travel_document = data.payload;
    },
    updateSertifikasiSupportDokumen: (state, data) => {
      state.userData.support_document = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.submitting = false;
      state.currentUser = action.payload.data;
      state.error = "";
      state.isLoggedIn = true;

      setCurrentUser(action.payload.data);

      setAccessToken(action.payload.access_token);
      // setRefreshToken(action.payload.refresh_token);

      SwalSuccess("Login Berhasil.", "User berhasil melakukan login.");
    });
    builder.addCase(login.rejected, (state, action) => {
      state.submitting = false;
      state.currentUser = null;
      state.error = action.payload.data.message;
      state.isLoggedIn = false;

      notifyError(action.payload.data.message);
    });

    // ----------------------------------

    builder.addCase(register.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.submitting = false;
      state.currentUser = action.payload.data.original.data;
      state.error = "";
      state.isLoggedIn = true;

      setCurrentUser(action.payload.data.original.data);

      setAccessToken(action.payload.data.original.access_token);
      // setRefreshToken(action.payload.refresh_token);

      SwalSuccess("Register Berhasil.", "User berhasil melakukan login.");
    });
    builder.addCase(register.rejected, (state, action) => {
      state.submitting = false;
      state.currentUser = null;
      state.error = action.payload.data.data;
      state.isLoggedIn = false;

      notifyError(action.payload.data.data);
    });
    // ---------------------------------------------
    builder.addCase(getProfileUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileUser.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload.data;
      state.isLoggedIn = true;

      setUserData(action.payload.data);
    });
    builder.addCase(getProfileUser.rejected, (state, action) => {
      state.loading = false;
      state.userData = null;
      state.currentUser = null;
      state.isLoggedIn = false;
    });
  },
});

export default userSlice.reducer;
export const {
  logout,
  updateFamily,
  updatePendidikan,
  updateSertifikasiProficiency,
  updateSertifikasiCompetency,
  updateSertifikasiOrganisasi,
  updateSertifikasiPekerjaan,
  updateSertifikasiSailExperience,
  updateSertifikasiTravelDocument,
  updateSertifikasiSupportDokumen,
} = userSlice.actions;

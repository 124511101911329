import React from "react";

const TableKeluarga = ({ data }) => {
  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Nama Lengkap
            </th>
            <th scope="col" className="px-6 py-3">
              Umur
            </th>
            <th scope="col" className="px-6 py-3">
              Jenis Kelamin
            </th>
            <th scope="col" className="px-6 py-3">
              Pekerjaan
            </th>
            <th scope="col" className="px-6 py-3">
              No HP
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={index}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {val?.nama_lengkap ?? "-"}
              </th>
              <td className="px-6 py-4"> {val?.age_family ?? "-"}</td>
              <td className="px-6 py-4">{val?.jenis_kelamin ?? "-"}</td>
              <td className="px-6 py-4">{val?.pekerjaan ?? "-"}</td>
              <td className="px-6 py-4">{val?.no_hp ?? "-"}</td>
              <td className="px-6 py-4">{val?.status ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableKeluarga;

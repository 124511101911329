import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/modules/profile.slice';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import {
    ArrowLeftCircleIcon,
  } from "@heroicons/react/24/outline";
import { notifyError } from '../../components/notification/Toast';

const ForgetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [isCheck, setIsCheck] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async () => {
        if(!email) return notifyError("Harap isi email.")
        
        setSubmitting(true)
        const res = await dispatch(forgotPassword({email}))
     
        if(res.payload.status === true){
            toast.success(`PASSWORD BERHASIL DI RESET`);
            setIsCheck(true)
            setSubmitting(false)
        }
        setSubmitting(false)
    }

  return (
    <section className="h-screen w-screen flex justify-center items-center bg-transparent z-10">
          <div className="px-6 h-full w-full text-gray-800">
            <div className="flex flex-col  justify-center items-center h-full">
            <button
                className="button-submit flex mb-2 bg-slate-500 px-4 absolute top-10 left-10" 
                onClick={() => navigate("/")}
                >
                {" "}
                <ArrowLeftCircleIcon className="icon-style mr-2" /> Kembali
        </button>
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
          
            <div className="my-2">
              <h1 className="text-xl font-semibold md:text-2xl text-black text-center">
                Lupa Password
              </h1>
            </div>
                 <div className="mb-3">
                    <label>Email</label>
                    <input
                      className="input-text w-100"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email..."

                    />
                  </div>

                  {isCheck && 
                    <div className='bg-[#548CA8] border border-[#548CA8] flex w-100 p-5 m-2 text-white text-center rounded-lg' >
                        <span>Reset Password Berhasil <br /> Silahkan Check Email: <br /> <strong>{email}</strong></span>
                    </div>
                  }

                  <button type="button" onClick={handleSubmit} className="button-submit">
                      {submitting ? (
                        <FontAwesomeIcon
                          icon="spinner"
                          size="xl"
                          className="text-white animate-spin"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
            </div>
          
            </div>
          </div>
    </section>
  )
}

export default ForgetPassword